<template>
  <div class="animation-wrapper">
    <div class="animation-container83"></div>
  </div>
</template>

<script>
import lottie from "lottie-web";
import Loader from "@/assets/loader3.json";

export default {
  mounted() {
    this.loadAnimation();
  },
  methods: {
    loadAnimation() {
      lottie.loadAnimation({
        container: document.querySelector(".animation-container83"),
        animationData: Loader,
        renderer: "svg",
        loop: true,
        autoplay: true,
      });
    },
  },
};
</script>

<style scoped>
.animation-wrapper {
  display: flex;
  border-radius: 50%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: transparent;
}
.animation-container83 {
  height: 350px;
  display: flex;
  align-items: center;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .animation-container83 {
    height: 140px;
  }
}
</style>
