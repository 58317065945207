<template>
    <div class="animation-wrapper-404">
    <div class="animation-container-404"></div>
</div>
  </template>
  
  <script>
  import lottie from 'lottie-web';
  import Error404 from './404.json';

  
  export default {
    mounted() {
      this.loadAnimation();
    },
    methods: {
      loadAnimation() {
        lottie.loadAnimation({
          container: document.querySelector('.animation-container-404'),
          animationData: Error404,
          renderer: 'svg',
          loop: true,
          autoplay: true,
        });
      },
    }
  }
  </script>
  
  <style scoped>
  .animation-wrapper-404 {
    display: flex;
    border-radius: 50%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: transparent;

  }
  .animation-container-404 {
    height: 200px;
    display: flex;
    align-items: center;
    position: relative;
  }
  </style>
  