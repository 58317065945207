<template>
  <svg
    class="star-purple-500"
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_372_9502)">
      <path
        d="M16.0423 23.0877L24.3041 28.0741L22.1116 18.676L29.4109 12.3527L19.7989 11.5372L16.0423 2.67383L12.2857 11.5372L2.67371 12.3527L9.97295 18.676L7.7805 28.0741L16.0423 23.0877Z"
        fill="#11365A"
      />
    </g>
    <defs>
      <clipPath id="clip0_372_9502">
        <rect width="32.0846" height="32.0846" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "StarPurple500",
  components: {},
  props: {},
  data() {},
};
</script>
<style scoped>
.star-purple-500,
.star-purple-500 * {
  box-sizing: border-box;
}
.star-purple-500 {
  flex-shrink: 0;
  width: 32.08px;
  height: 32.08px;
  position: relative;
  overflow: visible;
}
</style>
