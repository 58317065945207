<template>
  <div
    :class="[
      'table-container',
      { 'logged-in': !isMobile, 'not-logged-in': isMobile },
    ]"
  >
    <div class="frame-35732">
      <div class="frame-35733">
        <div
          class="tab-1"
          v-if="
            searchResults?.allOrdersResults &&
            searchResults?.allOrdersResults?.length > 0
          "
          :style="
            tab === 'active-orders'
              ? 'box-shadow: 0px -4px 24px 0px #8FC8FF; background: white;'
              : 'box-shadow: -4px -4px 8px 0px #fff, 4px 4px 8px 0px rgba(0, 0, 0, 0.25); background: #ecf4ff;'
          "
          @click="updateQueryTab('active-orders')"
        >
          Active Orders
        </div>
        <div
          class="tab-1"
          v-if="
            searchResults1?.allOrdersResults &&
            searchResults1?.allOrdersResults?.length > 0
          "
          :style="
            tab === 'delivered-reports'
              ? 'box-shadow: 0px -4px 24px 0px #8FC8FF; background: white;'
              : 'box-shadow: -4px -4px 8px 0px #fff, 4px 4px 8px 0px rgba(0, 0, 0, 0.25); background: #ecf4ff;'
          "
          @click="updateQueryTab('delivered-reports')"
        >
          Delivered Reports
        </div>
        <div
          class="tab-1"
          v-if="
            searchResults2?.cancelledOrdersResult &&
            searchResults2?.cancelledOrdersResult?.length > 0
          "
          :style="
            tab === 'cancelled-orders'
              ? 'box-shadow: 0px -4px 24px 0px #8FC8FF; background: white;'
              : 'box-shadow: -4px -4px 8px 0px #fff, 4px 4px 8px 0px rgba(0, 0, 0, 0.25); background: #ecf4ff;'
          "
          @click="updateQueryTab('cancelled-orders')"
        >
          Voided Orders
        </div>
      </div>
      <div
        class="tables-wrapper"
        v-if="
          (searchResults?.allOrdersResults &&
            searchResults?.allOrdersResults?.length > 0) ||
          (searchResults1?.allOrdersResults &&
            searchResults1?.allOrdersResults?.length > 0)
        "
      >
        <!-- Active Orders  -->
        <div
          class="table-wrapper"
          v-if="
            tab === 'active-orders' &&
            searchResults?.allOrdersResults &&
            searchResults?.allOrdersResults?.length > 0
          "
        >
          <div class="table-title-wrapper">
            <div class="table-title">Active Orders</div>
          </div>
          <!-- Desktop -->
          <table
            id="searchResultTable"
            v-if="
              !mobile &&
              searchResults?.allOrdersResults &&
              searchResults?.allOrdersResults?.length > 0
            "
          >
            <thead style="background: #ecf4ff">
              <tr>
                <th v-for="col in filteredTableColumns" :key="col">
                  {{ col }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(searchResult, index) in searchResults?.allOrdersResults"
                id="result-wrapper"
                :style="hoveredRow === index ? hoverStyle : {}"
                @mouseover="setHover(index)"
                @mouseout="clearHover"
                :key="index"
              >
                <td
                  v-if="
                    ['HEAD', 'MANAGER'].includes(userType) &&
                    searchResult?.teamName
                  "
                >
                  {{ searchResult?.teamName ? searchResult?.teamName : "-" }}
                </td>
                <td
                  v-if="
                    !['HEAD', 'MANAGER'].includes(userType) &&
                    searchResult?.orderedBy?.email
                  "
                >
                  {{
                    searchResult?.orderedBy?.email &&
                    searchResult?.orderedBy?.email !== null
                      ? searchResult?.orderedBy?.email
                      : "-"
                  }}
                </td>
                <td style="text-transform: capitalize">
                  {{ searchResult?.name?.toLowerCase() }}
                </td>
                <td>
                  {{ searchResult?.country }}
                </td>
                <td>
                  {{ searchResult?.type }}
                </td>
                <td v-if="!['HEAD', 'MANAGER'].includes(userType)">
                  <div
                    class="status-bg"
                    :style="{
                      background:
                        searchResult?.status === 'Order Received' ||
                        searchResult?.status === 'PENDING'
                          ? '#FFD700'
                          : searchResult?.status === 'Delivery Date'
                          ? '#32C226'
                          : '#26bbc2',
                      color:
                        searchResult?.status === 'Order Received' ||
                        searchResult?.status === 'PENDING'
                          ? '#11365A'
                          : 'white',
                    }"
                  >
                    <span>
                      {{ formatStatusText(searchResult?.status) }}
                    </span>
                  </div>
                </td>
                <td
                  @click="showOrderDetailsModal(searchResult)"
                  style="cursor: pointer; text-decoration: underline;"
                >
                  View Status
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Mobile -->
          <div
            id="searchResultDiv"
            v-if="
              mobile &&
              searchResults?.allOrdersResults &&
              searchResults?.allOrdersResults?.length > 0
            "
          >
            <div
              v-for="(searchResult, index) in searchResults?.allOrdersResults"
              :key="index"
              class="result-wrapper"
              :style="hoveredRow === index ? hoverStyle : {}"
              @mouseover="setHover(index)"
              @mouseout="clearHover"
            >
              <div class="row">
                <div class="label">Name:</div>
                <div class="value">{{ searchResult?.name?.toLowerCase() }}</div>
              </div>
              <div class="row">
                <div class="label">Country:</div>
                <div class="value">{{ searchResult.country }}</div>
              </div>
              <div class="row">
                <div class="label">Type:</div>
                <div class="value">{{ searchResult?.type?.toLowerCase() }}</div>
              </div>
              <div class="row">
                <div class="label">Status:</div>
                <div class="value">
                  <span
                    class="status-bg"
                    :style="{
                      background:
                        searchResult?.status === 'Order Received' ||
                        searchResult?.status === 'PENDING'
                          ? '#FFD700'
                          : searchResult?.status === 'Delivery Date'
                          ? '#32C226'
                          : '#26bbc2',
                      color:
                        searchResult?.status === 'Order Received' ||
                        searchResult?.status === 'PENDING'
                          ? '#11365A'
                          : 'white',
                    }"
                    >{{ formatStatusText(searchResult?.status) }}</span
                  >
                </div>
              </div>
              <div
                class="check-status"
                @click="showOrderDetailsModal(searchResult)"
                style="cursor: pointer"
              >
                View Status
              </div>
            </div>
          </div>
        </div>
        <div
          class="separation-line"
          v-if="
            tab === 'active-orders' &&
            searchResults?.allOrdersResults?.length > 0
          "
        ></div>
        <!-- Delivered Orders -->
        <div
          class="table-wrapper"
          v-if="
            tab === 'delivered-reports' &&
            searchResults1?.allOrdersResults &&
            searchResults1?.allOrdersResults?.length !== 0
          "
        >
          <div class="table-title-wrapper">
            <div class="table-title">Delivered Reports</div>
          </div>
          <!-- Desktop -->
          <table
            id="searchResultTable"
            v-if="
              !mobile &&
              searchResults1?.allOrdersResults &&
              searchResults1?.allOrdersResults?.length > 0
            "
          >
            <thead style="background: #ecf4ff">
              <tr>
                <th v-for="col in filteredTableColumns1" :key="col">
                  {{ col }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(
                  searchResult, index
                ) in searchResults1?.allOrdersResults"
                :style="hoveredRow1 === index ? hoverStyle : {}"
                :key="index"
              >
                <td
                  v-if="
                    ['HEAD', 'MANAGER'].includes(userType) &&
                    searchResult?.teamName
                  "
                >
                  {{ searchResult?.teamName ? searchResult?.teamName : "N/A" }}
                </td>
                <td style="text-transform: capitalize">
                  {{ searchResult?.name?.toLowerCase() }}
                </td>
                <td>
                  {{ searchResult?.country }}
                </td>
                <td>
                  {{
                    searchResult?.validityDate !== undefined
                      ? formatDate(searchResult?.validityDate)
                      : "Not Updated"
                  }}
                </td>
                <td class="action-buttons">
                  <div
                    class="reorder-button-outer"
                    title="Download Report"
                    :disabled="
                      !searchResult?.reportFileKey ||
                      (searchResult?.validityDate !== undefined &&
                        isValidityDateOver(searchResult?.validityDate))
                    "
                    :style="{
                      opacity:
                        (searchResult?.validityDate !== undefined &&
                          !isValidityDateOver(searchResult?.validityDate)) &&
                        (searchResult?.reportFileKey &&
                          searchResult?.reportFileKey !== 'offline')
                          ? '1'
                          : '0.5',
                      cursor:
                        (searchResult?.validityDate !== undefined &&
                          !isValidityDateOver(searchResult?.validityDate)) &&
                        (searchResult?.reportFileKey &&
                          searchResult?.reportFileKey !== 'offline')
                          ? 'pointer'
                          : 'not-allowed',
                    }"
                    @click="
                      (searchResult?.validityDate !== undefined &&
                        !isValidityDateOver(searchResult?.validityDate)) &&
                      (searchResult?.reportFileKey &&
                        searchResult?.reportFileKey !== 'offline')
                        ? downloadReport(
                            searchResult?.reportFileKey,
                            searchResult?.name?.toLowerCase(),
                            searchResult?.id
                          )
                        : none
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.2502 13.5001C20.4492 13.5001 20.6399 13.5791 20.7806 13.7198C20.9212 13.8604 21.0002 14.0512 21.0002 14.2501V19.5001C21.0002 19.8979 20.8422 20.2794 20.5609 20.5608C20.2796 20.8421 19.8981 21.0001 19.5002 21.0001H4.50024C4.10242 21.0001 3.72089 20.8421 3.43958 20.5607C3.15828 20.2794 3.00024 19.8979 3.00024 19.5001L3.00024 14.2501C3.00024 14.0512 3.07926 13.8604 3.21991 13.7198C3.36057 13.5791 3.55133 13.5001 3.75024 13.5001C3.94916 13.5001 4.13992 13.5791 4.28057 13.7198C4.42123 13.8604 4.50024 14.0512 4.50024 14.2501L4.50024 19.5001H19.5002V14.2501C19.5002 14.0512 19.5793 13.8604 19.7199 13.7198C19.8606 13.5791 20.0513 13.5001 20.2502 13.5001Z"
                        fill="#11365a"
                      />
                      <path
                        d="M7.71962 11.0301L11.4696 14.7801C11.5393 14.8499 11.622 14.9052 11.713 14.9429C11.8041 14.9807 11.9017 15.0001 12.0002 15.0001C12.0988 15.0001 12.1964 14.9807 12.2874 14.9429C12.3785 14.9052 12.4612 14.8499 12.5309 14.7801L16.2809 11.0301C16.4216 10.8894 16.5007 10.6985 16.5007 10.4995C16.5007 10.3005 16.4216 10.1096 16.2809 9.96889C16.1401 9.82816 15.9493 9.74909 15.7502 9.74909C15.5512 9.74909 15.3603 9.82816 15.2196 9.96889L12.7502 12.4392V3.74951C12.7502 3.5506 12.6712 3.35983 12.5306 3.21918C12.3899 3.07853 12.1992 2.99951 12.0002 2.99951C11.8013 2.99951 11.6106 3.07853 11.4699 3.21918C11.3293 3.35983 11.2502 3.5506 11.2502 3.74951V12.4392L8.78087 9.96889C8.64014 9.82816 8.44927 9.74909 8.25024 9.74909C8.05122 9.74909 7.86035 9.82816 7.71962 9.96889C7.57889 10.1096 7.49983 10.3005 7.49983 10.4995C7.49983 10.6985 7.57889 10.8894 7.71962 11.0301Z"
                        fill="#11365a"
                      />
                    </svg>
                    <!-- <span class="re-order">Download</span> -->
                  </div>
                  <div
                    class="reorder-button-outer"
                    title="Order Feedback"
                    :style="{
                      opacity:
                        searchResult?.feedbackStatusKey == 0 ? '1' : '0.5',
                      cursor:
                        searchResult?.feedbackStatusKey == 0
                          ? 'pointer'
                          : 'not-allowed',
                    }"
                    @click="
                      searchResult?.feedbackStatusKey == 0
                        ? toggleReviewsRating(
                            searchResult?.id,
                            searchResult?.hashedOrderId
                          )
                        : reviewAlreadySubmitted()
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.25 4.5H3.75003C3.3522 4.5 2.97067 4.65804 2.68937 4.93934C2.40806 5.22064 2.25003 5.60218 2.25003 6V21C2.2483 21.286 2.32921 21.5665 2.48305 21.8076C2.63689 22.0488 2.8571 22.2404 3.11721 22.3594C3.31543 22.4517 3.53138 22.4997 3.75003 22.5C4.10216 22.4992 4.4426 22.3736 4.71096 22.1456L4.7194 22.1391L7.78128 19.5H20.25C20.6479 19.5 21.0294 19.342 21.3107 19.0607C21.592 18.7794 21.75 18.3978 21.75 18V6C21.75 5.60218 21.592 5.22064 21.3107 4.93934C21.0294 4.65804 20.6479 4.5 20.25 4.5ZM20.25 18H7.50003C7.31994 18.0001 7.1459 18.065 7.00971 18.1828L3.75003 21V6H20.25V18Z"
                        fill="#11365a"
                      />
                    </svg>
                  </div>
                  <!-- <div class="reorder-button-outer" title="Re-Order the new report">
                    <div
                      class="reorder-button-border"
                      style="background: #11365a"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.75 20.25C9.75 20.5467 9.66203 20.8367 9.4972 21.0834C9.33238 21.33 9.09811 21.5223 8.82403 21.6358C8.54994 21.7494 8.24834 21.7791 7.95736 21.7212C7.66639 21.6633 7.39912 21.5204 7.18934 21.3107C6.97956 21.1009 6.8367 20.8336 6.77882 20.5426C6.72094 20.2517 6.75065 19.9501 6.86418 19.676C6.97771 19.4019 7.16997 19.1676 7.41665 19.0028C7.66332 18.838 7.95333 18.75 8.25 18.75C8.64782 18.75 9.02936 18.908 9.31066 19.1893C9.59196 19.4706 9.75 19.8522 9.75 20.25ZM18 18.75C17.7033 18.75 17.4133 18.838 17.1666 19.0028C16.92 19.1676 16.7277 19.4019 16.6142 19.676C16.5006 19.9501 16.4709 20.2517 16.5288 20.5426C16.5867 20.8336 16.7296 21.1009 16.9393 21.3107C17.1491 21.5204 17.4164 21.6633 17.7074 21.7212C17.9983 21.7791 18.2999 21.7494 18.574 21.6358C18.8481 21.5223 19.0824 21.33 19.2472 21.0834C19.412 20.8367 19.5 20.5467 19.5 20.25C19.5 19.8522 19.342 19.4706 19.0607 19.1893C18.7794 18.908 18.3978 18.75 18 18.75ZM22.4728 6.95062L20.0691 15.6019C19.9369 16.0745 19.6542 16.4911 19.2639 16.7885C18.8736 17.0859 18.397 17.2479 17.9062 17.25H8.64C8.14784 17.2498 7.66926 17.0886 7.27725 16.791C6.88523 16.4935 6.6013 16.0758 6.46875 15.6019L3.18 3.75H1.5C1.30109 3.75 1.11032 3.67098 0.96967 3.53033C0.829018 3.38968 0.75 3.19891 0.75 3C0.75 2.80109 0.829018 2.61032 0.96967 2.46967C1.11032 2.32902 1.30109 2.25 1.5 2.25H3.75C3.91397 2.24997 4.07343 2.30367 4.20398 2.40289C4.33452 2.50211 4.42895 2.64138 4.47281 2.79938L5.36156 6H21.75C21.8656 5.99998 21.9797 6.02669 22.0833 6.07805C22.1869 6.1294 22.2772 6.20401 22.3472 6.29605C22.4171 6.38809 22.4649 6.49506 22.4867 6.60861C22.5085 6.72216 22.5037 6.83922 22.4728 6.95062ZM20.7628 7.5H5.77875L7.91719 15.2006C7.96105 15.3586 8.05548 15.4979 8.18602 15.5971C8.31657 15.6963 8.47603 15.75 8.64 15.75H17.9062C18.0702 15.75 18.2297 15.6963 18.3602 15.5971C18.4908 15.4979 18.5852 15.3586 18.6291 15.2006L20.7628 7.5Z"
                          fill="white"
                        />
                      </svg>
                      <span class="re-order">Re-Order</span>
                    </div>
                  </div> -->
                </td>
              </tr>
            </tbody>
          </table>
          <!-- Mobile -->
          <div
            id="searchResultDiv"
            v-if="
              mobile &&
              searchResults1?.allOrdersResults &&
              searchResults1?.allOrdersResults?.length > 0
            "
          >
            <div
              v-for="(searchResult, index) in searchResults1?.allOrdersResults"
              :key="index"
              class="result-wrapper"
              :style="hoveredRow === index ? hoverStyle : {}"
              @mouseover="setHover(index)"
              @mouseout="clearHover"
            >
              <div class="row">
                <div class="label">Company Name:</div>
                <div class="value">{{ searchResult?.name?.toLowerCase() }}</div>
              </div>
              <div class="row">
                <div class="label">Country:</div>
                <div class="value">{{ searchResult.country }}</div>
              </div>
              <div class="row">
                <div class="label">Validity:</div>
                <div class="value">
                  {{
                    searchResult?.validityDate !== undefined
                      ? formatDate(searchResult?.validityDate)
                      : "Not updated"
                  }}
                </div>
              </div>
              <div
                class="reorder-button-outer"
                title="Download Report ( Note: Report will be delivered to the registered Email )"
                :disabled="
                  !searchResult?.reportFileKey ||
                  (searchResult?.validityDate !== undefined &&
                    isValidityDateOver(searchResult?.validityDate))
                "
                :style="{
                  opacity:
                    (searchResult?.validityDate !== undefined &&
                      !isValidityDateOver(searchResult?.validityDate)) ||
                    searchResult?.reportFileKey
                      ? '1'
                      : '0.5',
                  cursor:
                    (searchResult?.validityDate !== undefined &&
                      !isValidityDateOver(searchResult?.validityDate)) ||
                    searchResult?.reportFileKey
                      ? 'pointer'
                      : 'not-allowed',
                }"
                @click="
                  downloadReport(
                    searchResult?.reportFileKey,
                    searchResult?.name?.toLowerCase(),
                    searchResult?.id
                  )
                "
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.2502 13.5001C20.4492 13.5001 20.6399 13.5791 20.7806 13.7198C20.9212 13.8604 21.0002 14.0512 21.0002 14.2501V19.5001C21.0002 19.8979 20.8422 20.2794 20.5609 20.5608C20.2796 20.8421 19.8981 21.0001 19.5002 21.0001H4.50024C4.10242 21.0001 3.72089 20.8421 3.43958 20.5607C3.15828 20.2794 3.00024 19.8979 3.00024 19.5001L3.00024 14.2501C3.00024 14.0512 3.07926 13.8604 3.21991 13.7198C3.36057 13.5791 3.55133 13.5001 3.75024 13.5001C3.94916 13.5001 4.13992 13.5791 4.28057 13.7198C4.42123 13.8604 4.50024 14.0512 4.50024 14.2501L4.50024 19.5001H19.5002V14.2501C19.5002 14.0512 19.5793 13.8604 19.7199 13.7198C19.8606 13.5791 20.0513 13.5001 20.2502 13.5001Z"
                    fill="#11365a"
                  />
                  <path
                    d="M7.71962 11.0301L11.4696 14.7801C11.5393 14.8499 11.622 14.9052 11.713 14.9429C11.8041 14.9807 11.9017 15.0001 12.0002 15.0001C12.0988 15.0001 12.1964 14.9807 12.2874 14.9429C12.3785 14.9052 12.4612 14.8499 12.5309 14.7801L16.2809 11.0301C16.4216 10.8894 16.5007 10.6985 16.5007 10.4995C16.5007 10.3005 16.4216 10.1096 16.2809 9.96889C16.1401 9.82816 15.9493 9.74909 15.7502 9.74909C15.5512 9.74909 15.3603 9.82816 15.2196 9.96889L12.7502 12.4392V3.74951C12.7502 3.5506 12.6712 3.35983 12.5306 3.21918C12.3899 3.07853 12.1992 2.99951 12.0002 2.99951C11.8013 2.99951 11.6106 3.07853 11.4699 3.21918C11.3293 3.35983 11.2502 3.5506 11.2502 3.74951V12.4392L8.78087 9.96889C8.64014 9.82816 8.44927 9.74909 8.25024 9.74909C8.05122 9.74909 7.86035 9.82816 7.71962 9.96889C7.57889 10.1096 7.49983 10.3005 7.49983 10.4995C7.49983 10.6985 7.57889 10.8894 7.71962 11.0301Z"
                    fill="#11365a"
                  />
                </svg>
                <span class="re-order">Download</span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="separation-line"
          v-if="
            tab === 'delivered-reports' &&
            searchResults1?.allOrdersResults?.length > 0
          "
        ></div>
        <div
          class="table-wrapper"
          v-if="
            tab === 'cancelled-orders' &&
            searchResults2?.cancelledOrdersResult &&
            searchResults2?.cancelledOrdersResult?.length > 0
          "
        >
          <div class="table-title-wrapper">
            <div class="table-title">Voided Orders</div>
          </div>
          <table
            id="searchResultTable"
            v-if="
              !mobile &&
              searchResults2?.cancelledOrdersResult &&
              searchResults2?.cancelledOrdersResult.length > 0
            "
          >
            <thead style="background: #ecf4ff">
              <tr>
                <th v-for="col in filteredTableColumns2" :key="col">
                  {{ col }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(
                  searchResult, index
                ) in searchResults2?.cancelledOrdersResult"
                :style="hoveredRow2 === index ? hoverStyle : {}"
                :key="index"
              >
                <td
                  v-if="
                    ['HEAD', 'MANAGER'].includes(userType) &&
                    searchResult?.teamName
                  "
                >
                  {{ searchResult?.teamName ? searchResult?.teamName : "N/A" }}
                </td>
                <td style="text-transform: capitalize">
                  {{ searchResult?.name?.toLowerCase() }}
                </td>
                <td>
                  {{ searchResult?.country }}
                </td>
                <td>
                  {{ searchResult?.status }}
                </td>
              </tr>
            </tbody>
          </table>
          <div
            id="searchResultDiv"
            v-if="
              mobile &&
              searchResults2?.cancelledOrdersResult &&
              searchResults2?.cancelledOrdersResult?.length > 0
            "
          >
            <div
              v-for="(
                searchResult, index
              ) in searchResults2?.cancelledOrdersResult"
              :key="index"
              class="result-wrapper"
            >
              <div class="row">
                <div class="label">Company Name:</div>
                <div class="value">{{ searchResult?.name?.toLowerCase() }}</div>
              </div>
              <div class="row">
                <div class="label">Country:</div>
                <div class="value">{{ searchResult.country }}</div>
              </div>
              <div class="row">
                <div class="label">Validity:</div>
                <div class="value">
                  {{ searchResult?.status }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="separation-line"
          v-if="
            tab === 'cancelled-orders' &&
            searchResults2?.cancelledOrdersResult?.length > 0
          "
        ></div>
        <div
          class="pagination"
          v-if="
            (tab === 'active-orders' &&
              searchResults?.allOrdersResults?.length > 0) ||
            (tab === 'delivered-reports' &&
              searchResults1?.allOrdersResults?.length > 0) ||
            (tab === 'cancelled-orders' &&
              searchResults2?.cancelledOrdersResult?.length > 0)
          "
        >
          <div class="table-title2" v-if="tab === 'active-orders'">
            {{ (currentPage - 1) * 20 + 1 }}-
            {{ Math.min(currentPage * 20, searchResults?.activeOrderCount) }}
            of {{ searchResults?.activeOrderCount }} Orders
          </div>
          <div class="table-title2" v-if="tab === 'delivered-reports'">
            {{ (currentPage - 1) * 20 + 1 }}-
            {{
              Math.min(currentPage * 20, searchResults1?.deliveredOrderCount)
            }}
            of {{ searchResults1?.deliveredOrderCount }} Orders
          </div>
          <div class="table-title2" v-if="tab === 'cancelled-orders'">
            {{ (currentPage - 1) * 20 + 1 }}-
            {{
              Math.min(currentPage * 20, searchResults2?.cancelledOrderCount)
            }}
            of {{ searchResults2?.cancelledOrderCount }} Orders
          </div>
          <vs-pagination
            :total-pages="
              (tab === 'active-orders' && searchResults?.noOfPages) ||
              (tab === 'delivered-reports' && searchResults1?.noOfPages) ||
              (tab === 'cancelled-orders' && searchResults2?.noOfPages)
            "
            :page-gap="mobile ? 1 : 2"
            :current-page="currentPage"
            @change="changePage"
          ></vs-pagination>
        </div>
      </div>
    </div>
    <div
      class="no-data-found"
      v-if="
        searchMessage === 'No active orders found' &&
        searchMessage1 === 'No previously delivered orders found' &&
        searchMessage2 === 'No cancelled orders found'
      "
    >
      <NoData />
      <span>No data found</span>
    </div>
  </div>
  <OrderDetailsModel
    v-if="showOrderDetails"
    :hideOrderDetailsModal="hideOrderDetailsModal"
    :searchResults="selectedResult"
  />
  <ReviewsRating
    v-if="toggleReviews"
    @close="toggleReviewsRating"
    @submit="submitReview"
    :orderId="this.orderId"
    :hashedOrderId="hashedOrderId !== undefined ? hashedOrderId : '-'"
  />
  <Toastify ref="toastContainer" />
  <div class="loader-wrapper" v-if="isLoading">
    <LoaderAnimation />
  </div>
</template>
<script>
import VueCookies from "vue-cookies";
import NoData from "@/assets/Animation/NoData/NoData.vue";
import Toastify from "@/components/Toastify.vue";
import OrderDetailsModel from "@/views/Modals/OrderDetailsActiveOrders.vue";
import ReviewsRating from "@/views/Modals/Rating&Reviews.vue";
import VsPagination from "@vuesimple/vs-pagination";
import LoaderAnimation from "@/assets/Animation/LoaderAnimation.vue";
import {
  DownloadReportPDF,
  RepositoryOrders,
  ActiveOrders,
  CancelledOrders,
} from "@/api/APIs";

export default {
  name: "BankUserOrders",
  components: {
    NoData,
    Toastify,
    OrderDetailsModel,
    ReviewsRating,
    VsPagination,
    LoaderAnimation,
  },
  props: {
    property1: {
      type: String,
      default: "default",
    },
  },
  data() {
    return {
      tabelColumns: ["HEAD", "MANAGER"].includes(VueCookies.get("user-type"))
        ? ["Team", "Company Name", "Country", "Type", "Action"]
        : [
            "User Email",
            "Company Name",
            "Country",
            "Type",
            "Current Status",
            "Action",
          ],
      searchResults: null,
      searchMessage: null,
      resultsAdditionalInformation: null,
      showOrderDetails: false,
      selectedResult: null,
      isSafari: false,
      hoveredRow: null,
      hoveredRow1: null,
      repositoryTabelColumns: [
        "Team",
        "Company Name",
        "Country",
        "Validity",
        "Actions",
      ],
      searchResults1: null,
      searchMessage1: null,
      cancelledTabelColumns: [
        "Team",
        "Company Name",
        "Country",
        "Order Status",
      ],
      resultsAdditionalInformation1: null,
      searchResults2: null,
      searchMessage2: null,
      resultsAdditionalInformation2: null,
      isMobile: window.innerWidth < 1023,
      mobile: window.innerWidth <= 767,
      tab: null,
      userType: VueCookies.get("user-type"),
      toggleReviews: false,
      orderId: null,
      hashedOrderId: null,
      currentPage: 1,
      isLoading: false,
    };
  },
  computed: {
    showTeamColumn() {
      return this.searchResults?.allOrdersResults?.some(
        (result) => result?.teamName
      );
    },
    filteredTableColumns() {
      return this.showTeamColumn
        ? this.tabelColumns
        : this.tabelColumns.filter((col) => col !== "Team");
    },
    showTeamColumn1() {
      return this.searchResults1?.allOrdersResults.some(
        (result) => result?.teamName
      );
    },
    filteredTableColumns1() {
      return this.showTeamColumn1
        ? this.repositoryTabelColumns
        : this.repositoryTabelColumns.filter((col) => col !== "Team");
    },
    showTeamColumn2() {
      return this.searchResults2?.cancelledOrdersResult?.some(
        (result) => result?.teamName
      );
    },
    filteredTableColumns2() {
      return this.showTeamColumn2
        ? this.cancelledTabelColumns
        : this.cancelledTabelColumns.filter((col) => col !== "Team");
    },
    hoverStyle() {
      return this.isSafari
        ? {
            borderRadius: "13px",
            background: "#11365a1f",
          }
        : {
            borderRadius: "13px",
            background:
              // "#f2f2f2",
              "linear-gradient(133deg, rgba(38, 187, 194, 0.2) 27.88%, rgba(24, 207, 215, 0) 79.91%), #3673ae14",
          };
    },
  },
  created() {
    this.tab = this.$route.query.tab;
    this.isSafari = this.detectSafari();
    Promise.all([
      this.MyOrdersList(),
      this.MyRepositoryOrdersList(),
      this.CancelledOrdersList(),
    ])
      .then(() => {
        if (
          this.searchMessage === "No active orders found" &&
          this.searchMessage1 === "No previously delivered orders found" &&
          this.searchMessage2 === "No cancelled orders found"
        ) {
          this.updateQueryTab("active-orders");
        } else if (
          this.searchMessage === "No active orders found" &&
          this.searchMessage1 === "No previously delivered orders found"
        ) {
          this.updateQueryTab("cancelled-orders");
        } else if (this.searchMessage === "No active orders found") {
          this.updateQueryTab("delivered-reports");
        }
      })
      .catch((error) => {
        console.error("Error fetching orders:", error);
      });
  },
  mounted() {
    this.currentPage = 1;
    window.addEventListener("resize", this.handleResponsive);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResponsive);
  },

  methods: {
    changePage(page) {
      this.currentPage = page;
      if (this.tab === "active-orders") {
        this.MyOrdersList(page);
      } else if (this.tab === "delivered-reports") {
        this.MyRepositoryOrdersList(page);
      } else if (this.tab === "cancelled-orders") {
        this.CancelledOrdersList(page);
      }
    },
    submitReview() {
      this.MyRepositoryOrdersList();
    },
    toggleReviewsRating(id, internalid) {
      if (id) {
        this.orderId = id;
        this.hashedOrderId = internalid;
      }
      this.toggleReviews = !this.toggleReviews;
    },
    reviewAlreadySubmitted() {
      return this.$refs.toastContainer.addToast(`Feedback already submitted.`, {
        type: "warning",
      });
    },
    formatStatusText(status) {
      if (status === "PENDING") {
        status = "Order received";
      }
      return status
        ? status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()
        : "";
    },
    isValidityDateOver(validityDate) {
      const today = new Date();
      const validity = new Date(validityDate);
      return validity < today;
    },
    async downloadReport(fileKey, fileName, id) {
      const capitalizedFileName = fileName
        ?.toLowerCase()
        .replace(/^(.)|\s+(.)/g, function ($1) {
          return $1.toUpperCase();
        });
      let payload = {
        fileKey: fileKey,
      };
      try {
        const response = await DownloadReportPDF(payload);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(
          new File(
            [response.data],
            `${capitalizedFileName} Credence Report-${id}.pdf`,
            {
              type: "application/pdf",
            }
          )
        );
        link.download = `${capitalizedFileName} Credence Report-${id}.pdf`;
        document.body.append(link);
        link.click();
        link.remove();
        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Oops! Something went wrong, Please try again later",
            {
              type: "error",
            }
          );
        }
      }
    },
    handleResponsive() {
      this.isMobile = window.innerWidth < 1023;
    },
    detectSafari() {
      const ua = navigator.userAgent.toLowerCase();
      return ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1;
    },
    setHover(index) {
      this.hoveredRow = index;
    },
    clearHover() {
      this.hoveredRow = null;
    },
    setHover1(index) {
      this.hoveredRow1 = index;
    },
    clearHover1() {
      this.hoveredRow1 = null;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        day: "2-digit",
        month: "short",
        year: "numeric",
      };

      let formattedDate = date.toLocaleDateString("en-US", options);
      formattedDate;

      return formattedDate;
    },
    async CancelledOrdersList(page) {
      // this.$root.showLoader();
      this.isLoading = true;
      this.searchResults2 = null;
      this.searchMessage2 = null;
      this.resultsAdditionalInformation2 = null;
      try {
        let response = await CancelledOrders(page);
        this.searchMessage2 = response?.message;
        this.searchResults2 = response;
        this.resultsAdditionalInformation2 = [];
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          this.$refs.toastContainer.addToast(
            "Server Error, please try again later.",
            { type: "error" }
          );
        }
      }

      // this.$root.hideLoader();
      this.isLoading = false;
    },
    async MyRepositoryOrdersList(page) {
      // this.$root.showLoader();
      this.isLoading = true;
      this.searchResults1 = null;
      this.searchMessage1 = null;
      this.resultsAdditionalInformation1 = null;
      try {
        let response = await RepositoryOrders(page);
        this.searchMessage1 = response?.message;
        this.searchResults1 = response;
        this.resultsAdditionalInformation1 = [];
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          this.$refs.toastContainer.addToast(
            "Server Error, please try again later.",
            { type: "error" }
          );
        }
      }

      // this.$root.hideLoader();
      this.isLoading = false;
    },
    async MyOrdersList(page) {
      // this.$root.showLoader();
      this.isLoading = true;
      this.searchResults = null;
      this.searchMessage = null;
      this.resultsAdditionalInformation = null;
      try {
        let response = await ActiveOrders(page);
        this.searchMessage = response?.message;
        this.searchResults = response;
        this.resultsAdditionalInformation = [];
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          this.$refs.toastContainer.addToast(
            "Server Error, please try again later.",
            { type: "error" }
          );
        }
      }
      this.isLoading = false;
      // this.$root.hideLoader();
    },
    showOrderDetailsModal(searchResult) {
      this.selectedResult = searchResult;
      this.showOrderDetails = true;
    },
    hideOrderDetailsModal() {
      this.showOrderDetails = false;
    },
    updateQueryTab(tab) {
      this.currentPage = 1;
      this.tab = tab;
      this.$router.push({ path: "/MyOrders", query: { tab: tab } });
    },
  },
};
</script>
<style scoped>
.no-data-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16%;
  color: #11365a;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  gap: 2vh;
}
.logged-in {
  margin-left: 18vw;
  padding-top: 55px !important;
}

.not-logged-in {
  width: 100%;
}
.no-data-found span {
  margin-left: 7%;
  margin-top: 1rem;
  line-height: 300%;
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-Light", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
  opacity: 0.7;
}
#searchResultTable {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  font-family: "Montserrat-Regular", sans-serif;
}
.tables-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem 1.5rem 0.5rem 1.5rem;
  border-radius: 16px;
  border-top-left-radius: 0;
  background-color: white;
  /* gap: 20px; */
  position: relative;
}
.table-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  /* padding: 2rem 1.5rem;
    border-radius: 16px;
    background-color: white; */
  height: 65vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  gap: 15px;
}
.table-title-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.table-title {
  color: #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  line-height: 180%;
  text-align: left;
  font-size: 20px;
  font-weight: 500;
}
.view-all-button {
  border-radius: 13px;
  border: 2px solid #11365a;
  padding: 8px 36px;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  color: #11365a;
  font-size: 16px;
  font-weight: 600;
}
#searchResultTable th {
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  color: #11365a;
  padding: 10px 16px;
  min-width: 30px;
}

#searchResultTable td {
  text-align: left;
  padding: 10px 16px;
  color: #11365a;
  font-size: 16px;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: -0.16px;
}

#order-buttons {
  text-align: center;
  min-width: 250px;
}

.order-buttons-grid-display {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

#order-buttons button {
  font-weight: normal;
  width: 120px;
  height: 50px;
  box-shadow: 0 1px 2px #aaa;
  font-size: 14px;
}

.oldReportButton {
  background-color: #1b3557;
  color: #ffffff;
}

.newReportButton {
  background-color: #80ccd2;
  color: #000000;
}

#manual-order-div {
  margin-top: 10px;
}

#manual-order-button {
  height: 35px;
  width: 160px;
}
.table-container,
.table-container * {
  box-sizing: border-box;
}
.table-container {
  padding-top: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  /* margin-left: 18vw; */
}
.status-bg {
  padding: 2px 16px;
  border-radius: 50px;
  font-family: "Montserrat-Regular", sans-serif;
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  display: inline-block;
}
.separation-line {
  width: 100%;
  height: 2px;
  opacity: 0.2;
  background: #3e4855;
}
.action-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 20px;
}
.button-outer {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  width: 48px;
  background: white;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.18);
  cursor: pointer;
}
.reorder-button-outer {
  /* border-radius: 16px; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  /* min-width: 200px; */
  /* padding: 3px; */
  /* background: white; */
  /* box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.18); */
  /* cursor: pointer; */
  /* border: none; */
  /* outline: none; */
  text-align: left;
}
.button-border {
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 86%;
  height: 86%;
}
.reorder-button-border {
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 5px 10px;
}
.re-order {
  color: #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  line-height: 180%;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
}
/* .button-border:hover {
    background: #c4aa02;
  } */

.frame-35733 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
}
.tab-1 {
  display: flex;
  min-width: 190px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 16px 16px 0px 0px;
  background: #ecf4ff;
  color: var(--Dark-Blue, #11365a);
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.4px;
}
.frame-35732 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 2rem 3rem 1rem 3rem;
}
.loader-wrapper {
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 8%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.table-title2 {
  color: var(--Dark-Blue, #11365a);
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.4px;
  margin-top: 5px;
}

@media only screen and (max-width: 767px) {
  .table-container {
    padding-top: 1rem;
  }
  .tables-wrapper {
    padding: 1.2rem 1rem;
    border-top-right-radius: 0px;
  }
  .table-wrapper {
    gap: 1rem;
  }
  .status-bg {
    padding: 5px 10px;
    line-height: normal;
    font-size: 14px;
  }
  #searchResultDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    width: 100%;
    gap: 20px;
  }
  .result-wrapper {
    display: flex;
    padding: 21.634px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 21.634px;
    border: 1.352px solid rgba(0, 0, 0, 0.15);
    background: #fff;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    color: #11365a;
    gap: 20px;
  }
  .check-status {
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 10.817px;
    border: 1.352px solid #11365a;
    color: #11365a;
    font-family: "Montserrat-Regular", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.216px;
  }
  .label {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    color: #11365a;
    font-family: "Montserrat-Regular", sans-serif;
    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 37.859px; */
    letter-spacing: -0.216px;
    width: 20vw;
  }
  .value {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    color: #11365a;
    font-family: "Montserrat-Regular", sans-serif;
    text-align: left;
    font-size: 16px;
    font-style: normal;
    /* font-weight: 400; */
    letter-spacing: -0.216px;
    text-transform: capitalize;
    flex: 1;
  }
  .reorder-button-outer {
    width: 100%;
  }
  .tab-1 {
    padding: 7px 20px;
    min-width: auto;
    font-size: 16px;
    line-height: 20px;
  }
  .frame-35732 {
    padding: 0.5rem 0.8rem;
  }
  .frame-35733 {
    gap: 10px;
  }
  .table-title {
    font-size: 18px;
    font-weight: 600;
  }
  .view-all-button {
    padding: 6px 20px;
    font-size: 14px;
  }
  .reorder-button-outer {
    width: 100%;
    margin-top: 10px;
  }
  .table-wrapper {
    height: 67vh;
  }
  .pagination {
    justify-content: center;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
  .table-container {
    padding-top: 0;
  }
  .table-wrapper {
    height: 63vh;
  }
}
@media only screen and (min-width: 1156px) {
}
</style>
<style>
.vs-pagination {
  /* padding: 0 !important; */
  gap: 5px;
}
.vs-pagination > li.vs-pagination--active a {
  color: white;
  background: #11365a;
}
.vs-pagination > li > a {
  border: 1px solid rgba(17, 54, 90, 0.5);
  border-radius: 5px;
  /* color: #11365A; */
}
</style>
