<template>
  <div class="terms-container">
    <h2 class="title">
      Terms and Conditions for Credence Business Information Report Services
    </h2>
    <div class="sub-title">Last Updated: 28 Aug 2024</div>
    <div class="content">
      <p>
        These Terms and Conditions ("Agreement") govern the collection,
        processing, and use of data provided by Data subject ("You" or "Your")
        to Interlinkages India Private Limited ("We," "Us," or "Our"), a
        licensed reseller and authorised service provider of the Credence
        Business Information Report ("Report") in India. Credence is a
        proprietary brand of Interlinkages Limited, Hong Kong.
      </p>
      <ol style="padding-inline-start: 18px">
        <li style="font-weight: 600">Consent for Data Collection</li>
        <ul>
          <li>
            Information that you provide by filling in forms on our site
            www.credencedata.com (our site). This includes information provided
            at the time of registering to use our site, subscribing to our
            service, posting material, or requesting further services. We may
            also ask you for information at other times, for example in
            connection with a promotion or when you report a problem with our
            site.
          </li>
        </ul>

        <li style="font-weight: 600">Data Usage</li>
        <ul>
          <li>
            The data You provide will be utilized solely for the purpose of
            generating the a Business Information Report ("Report") that You
            have ordered. These Reports are intended for Your internal use only
          </li>
          <li>
            You hereby grant Us and Interlinkages Limited a royalty-free,
            perpetual, worldwide right and license to use, and to authorize
            others to use, Your data. This includes, but is not limited to, the
            rights to reproduce, analyze, incorporate, modify, display, create
            derivative works from, and distribute Your data in connection with
            the production, distribution, and marketing of Reports to other
            customers.
          </li>
          <li>
            You explicitly consent to the use of Your data and any resulting
            Reports by Interlinkages Limited and in the Reports sold through our
            websites www.credencedata.com and www.credencedata.info, for the
            purposes of producing, distributing, and marketing Reports to other
            customers. In this regard, You grant Us and Interlinkages Limited a
            non-exclusive, royalty-free license to use Your data.
          </li>
          <li>
            You represent and warrant that: (i) You possess all necessary legal
            rights, title, consents, and authority to provide Your data
            (including, but not limited to, Personal Data) to Us; and (ii) You
            will not provide Us with any Personal Data that reveals racial or
            ethnic origin, political opinions, religious or philosophical
            beliefs, genetic or biometric data for the purpose of uniquely
            identifying a natural person, or data concerning health or sexual
            orientation. Unless otherwise agreed by the parties, You shall be
            deemed the data user (and/or data controller), and We shall act as
            the data processor concerning any Personal Data supplied, in
            accordance with applicable Privacy Laws.
          </li>
          <li>
            We will not use Your data for any other purpose without Your
            explicit consent, except as required by law.
          </li>
        </ul>

        <li style="font-weight: 600">Payment and Delivery</li>
        <ul>
          <li>
            You agree to pay the specified fee for the Report at the time of
            Your order. Payment must be received before the Report is delivered
            to You.
          </li>
          <li>
            Upon receipt of payment, We will prepare and deliver the Report to
            You in accordance with the timeline specified at the time of Your
            order.
          </li>
        </ul>

        <li style="font-weight: 600">Brand and Intellectual Property Rights</li>
        <ul>
          <li>
            The Credence Business Information Report is a proprietary product of
            Interlinkages Limited, Hong Kong. Interlinkages India Private
            Limited is a licensed reseller of these Reports under the Credence
            brand in India.
          </li>
          <li>
            All intellectual property rights in the Reports, including data
            analysis, presentation, and the Credence brand, remain with
            Interlinkages Limited, Hong Kong. Upon payment, You are granted a
            non-exclusive, non-transferable license to use the Report for Your
            internal business purposes.
          </li>
        </ul>

        <li style="font-weight: 600">Confidentiality and Data Security</li>
        <ul>
          <li>
            We are committed to maintaining the confidentiality of Your data. We
            will implement reasonable security measures to protect Your data
            from unauthorized access, disclosure, or misuse.
          </li>
          <li>
            You acknowledge that no method of transmission over the internet or
            method of electronic storage is completely secure, and We cannot
            guarantee absolute security.
          </li>
        </ul>

        <li style="font-weight: 600">Limitation of Liability</li>
        <ul>
          <li>
            To the extent permitted by law, Our total liability under or in
            connection with this Agreement, whether in contract, tort (including
            negligence), breach of statutory duty, or otherwise, shall not
            exceed the amount paid by You for the Report.
          </li>
          <li>
            We shall not be liable for any indirect, consequential, or
            incidental damages arising from the use of the Reports.
          </li>
        </ul>

        <li style="font-weight: 600">Scope of Communication</li>
        <ul>
          <li>
            Any agreements, confirmations, or authorizations made between You
            and Us via WhatsApp, email, or other forms of electronic
            communication shall be considered as legally binding and enforceable
            as if they were executed in writing and signed by both parties. By
            engaging in communications via WhatsApp or email with Us, You
            acknowledge and agree that any decisions, agreements, or commitments
            made through these channels are final and binding upon both parties.
          </li>
        </ul>

        <li style="font-weight: 600">Governing Law</li>
        <ul>
          <li>
            This Agreement shall be governed by and construed in accordance with
            the laws of India. Any disputes arising out of or in connection with
            this Agreement shall be subject to the exclusive jurisdiction of the
            courts in Gurugram.
          </li>
        </ul>

        <li style="font-weight: 600">Acceptance of Terms</li>
        <ul>
          <li>
            By providing Your data and proceeding with payment, You acknowledge
            that You have read, understood, and agree to be bound by these Terms
            and Conditions.
          </li>
        </ul>
      </ol>
    </div>
    <!-- <div class="close-button" @click="closeTab">Close</div> -->
  </div>
</template>

<script>
export default {
  name: "WhatsappTermsAndConditions",
};
</script>

<style scoped>
.terms-container {
  width: 85%;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  text-align: justify;
  margin-bottom: 20px;
  margin: 7% 0 5% 0;
  padding: 0 10%;
}
.sub-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.2px;
  text-align: left;
  padding: 0 10%;
  align-self: stretch;
}

.content {
  font-size: 16px;
  padding: 0 10%;
}

.content p {
  text-align: justify;
}

.content h2 {
  margin-top: 20px;
}

.content ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: justify;
}

.content li {
  margin-bottom: 10px;
  text-align: justify;
}

.close-button {
  border-radius: 10px;
  background: #11365a;
  color: white;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.2px;
  padding: 8px 30px;
  margin: 8% 0 5% 0;
  cursor: pointer;
}
</style>
