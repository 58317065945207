<template>
  <div class="bg-b-lue" v-on:click.self="close()">
    <div class="frame-35628" v-if="companyName !== ''">
      <CompletedAnimationJSON id="completed-animation" />
      <div class="company-name-wrapper" style="margin-top: 10px">
        Thanks, Your query has been recorded.
      </div>
      <div class="report-delivery-time">
        You support request number is - {{ supportReqNumber }}.
      </div>
      <div
        class="button"
        style="
          background-color: white;
          border: 2px solid #11365a;
          padding: 14px 0px;
        "
        v-on:click.prevent="close()"
      >
        <div class="button-text" style="color: #11365a">Done</div>
      </div>
    </div>
  </div>
</template>
<script>
import CompletedAnimationJSON from "../../assets/Animation/CompletedAnimation.vue";

export default {
  name: "ContactFormSubmission",
  components: {
    CompletedAnimationJSON,
  },
  props: {
    supportReqNumber: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  z-index: 9999;
}
.frame-35628 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 420px;
  position: absolute;
  background: #ffffff;
  border-radius: 24px;
  position: relative;
  box-shadow: 0px 24px 24px 0px rgba(17, 54, 90, 0.2);
  padding: 2rem 3rem;
}
.company-name-wrapper {
  color: var(--Dark-Blue, #11365a);
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5px;
}
.report-delivery-time {
  color: var(--Text-Mid, #3e4855);
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.18px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.button {
  border-radius: 13px;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-width: 150px;
  width: 100%;
  cursor: pointer;
}
.button-text {
  color: #ffffff;
  text-align: center;
  font-family: "NunitoSans-SemiBold", sans-serif;
  font-size: 18px;
  font-weight: 600;
  flex: 1;
}

@media only screen and (max-width: 767px) {
  .frame-35628 {
    width: 75%;
    padding: 1rem 2rem;
  }
  #completed-animation {
    width: 75px;
    height: 75px;
  }
  .company-name-wrapper {
    font-size: 16px !important;
  }
  .report-delivery-time {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 14px;
  }
  .button {
    width: 70%;
    padding: 10px 0 !important;
  }
  .button-text {
    font-size: 14px;
  }
}
</style>
