<template>
    <div class="animation-wrapper">
    <div class="animation-container1" style="transform: rotate(180deg); padding-top: 2px;"></div>
    <div class="animation-container2"></div>
</div>
  </template>
  
  <script>
  import lottie from 'lottie-web';
  import AnimationJSON from '@/assets/Animation.json';
  
  export default {
    mounted() {
      this.loadAnimation();
      this.loadAnimation2();
    },
    methods: {
      loadAnimation() {
        lottie.loadAnimation({
          container: document.querySelector('.animation-container1'),
          animationData: AnimationJSON,
          renderer: 'svg',
          loop: true,
          autoplay: true,
        });
      },
      loadAnimation2() {
        lottie.loadAnimation({
          container: document.querySelector('.animation-container2'),
          animationData: AnimationJSON,
          renderer: 'svg',
          loop: true,
          autoplay: true,
        });
      }
    }
  }
  </script>
  
  <style scoped>
  .animation-wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    top: 3%;
    position: relative;
  }
  .animation-container1, .animation-container2 {
    width: 30%;
    display: flex;
    align-items: center;
  }


/* Media query */
@media only screen and (max-width: 767px) {
.animation-wrapper {
  top: 0;
}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .animation-wrapper {
  top: 0;
}
}

@media only screen and (min-width: 1024px) {
}
  </style>
  