<template>
  <div class="terms-container">
    <h1 class="title">Terms and Conditions | Privacy Policy</h1>
    <div class="content">
      <p>
        Interlinkages Limited (“the Company”, “Interlinkages” or “Credence”) is
        committed to protecting and respecting your privacy. This policy
        (together with any other documents referred to in it) sets out the basis
        on which any personal data we collect from you, or that you provide to
        us, will be processed by us. Please read the following carefully to
        understand our views and practices regarding your personal data and how
        we will treat it.
      </p>

      <h2>Information Collection</h2>
      <p>
        In order to provide a better service to you, we may collect and process
        the following data about you:
      </p>
      <ul>
        <li>
          Information that you provide by filling in forms on our site
          www.credencedata.com (our site). This includes information provided at
          the time of registering to use our site, subscribing to our service,
          posting material, or requesting further services. We may also ask you
          for information at other times, for example in connection with a
          promotion or when you report a problem with our site.
        </li>
        <li>If you contact us, we may keep a record of that correspondence.</li>
        <li>
          We may also ask you to complete surveys that we use for research
          purposes, although you do not have to respond to them.
        </li>
        <li>
          Details of orders that you place on our site and of the fulfilment of
          your orders.
        </li>
        <li>
          Details of your visits to our site and the resources that you access.
        </li>
        <li>
          We only retain personal data for so long as it is necessary. Data may
          be archived as long as the purpose for which the data was used still
          exists.
        </li>
      </ul>

      <h2>Use of Information</h2>
      <p>
        The purposes for which information may be used by us in and outside the
        Hong Kong Special Administrative Region include:
      </p>
      <ul>
        <li>
          Ensuring that content from our site is presented in the most effective
          manner for you and for your computer.
        </li>
        <li>
          Providing you with alerts, newsletter, education materials or
          information that you requested or signed up to.
        </li>
        <li>
          Carrying out our obligations arising from any contracts entered into
          between you and us.
        </li>
        <li>
          Allowing you to participate in interactive features of our service,
          when you choose to do so.
        </li>
        <li>
          Designing and conducting surveys/questionnaires for client
          profiling/segmentation, statistical analysis, improving and furthering
          the provision our products and services.
        </li>
        <li>
          Complying with laws and regulations applicable to us or any of our
          affiliates in or outside the Hong Kong Special Administrative Region.
        </li>
        <li>
          Legal proceedings, including collecting overdue amounts and seeking
          professional advices.
        </li>
        <li>
          Researching, designing and launching services or products including
          seminars/events/forums.
        </li>
        <li>
          Promoting and marketing services and products subject to your exercise
          of the opt-out right.
        </li>
        <li>Purposes directly related or incidental to the above.</li>
      </ul>
      <h2>Direct Marketing</h2>
      <p>
        We intend to use your data in direct marketing and we require your
        consent (which includes an indication of no objection) for that purpose.
        In this connection, please note that: (a) your name, contact details
        (including address, contact number, email address), products and
        services information, transaction pattern and behaviour, background and
        demographic data held by us from time to time may be used by us in
        direct marketing; (b) the following classes of services, products and
        subjects may be marketed in direct marketing: (i) services and products
        related to our site and/or our affiliates (including marketing
        affiliates programs we are a part of); (ii) reward, loyalty or
        privileges programmes, promotional offers and related services; and
        (iii) invitations to events such as seminars/webinars/tele-seminars,
        conferences, live programs or events. (c) We may conduct direct marketing
        via fax, email, direct mail, telephone and other means of communication
        or send e-newsletters to you. You may choose not to receive promotional
        materials by simply telling us (see below for contact details), and we
        will cease to do so without charge.
      </p>
      <h2>Disclosure of Information</h2>
      <p>
        We will keep the personal data we hold confidential but may provide
        information to:
      </p>
      <ul>
        <li>
          Personnel, agents, advisers, auditors, contractors, financial
          institutions, and service providers in connection with our operations
          or services.
        </li>
        <li>
          Our overseas offices, affiliates, business partners and counterparts
          (if any).
        </li>
        <li>Persons under a duty of confidentiality to us.</li>
        <li>
          Persons to whom we are required to make disclosure under applicable
          laws and regulations in or outside the Hong Kong Special
          Administrative Region.
        </li>
        <li>
          Actual or proposed transferees or participants of our services in or
          outside the Hong Kong Special Administrative Region.
        </li>
      </ul>

      <h2>Security</h2>
      <p>
        All information you provide to us is stored on our secure servers. Any
        payment transactions will be encrypted using SSL technology. Where we
        have given you (or where you have chosen) a password which enables you
        to access certain parts of our site, you are responsible for keeping
        this password confidential. We ask you not to share a password with
        anyone. Unfortunately, the transmission of information via the internet
        is not completely secure. Although we will do our best to protect your
        personal data, we cannot guarantee the security of your data transmitted
        to our site; any transmission is at your own risk. Once we have received
        your information, we will use strict procedures and security features to
        try to prevent unauthorised access.
      </p>
      <h2>Cookies and Tracking Technologies</h2>
      <p>
        We use cookies and similar tracking technologies to track the activity
        on our site and hold certain information. Cookies are files with a small
        amount of data which may include an anonymous unique identifier. You can
        instruct your browser to refuse all cookies or to indicate when a cookie
        is being sent. However, if you do not accept cookies, you may not be
        able to use some portions of our site.
      </p>

      <h2>Data Transfers</h2>
      <p>
        We may hold your data on servers in Hosting services of Amazon, USA and
        any other territories as we see fit from time to time. We may also
        transfer your data to our overseas offices or to any people listed in
        the Disclosure of Information section above, who may be located in or
        outside of the Hong Kong Special Administrative Region.
      </p>

      <h2>PDPO Compliance</h2>
      <p>
        We comply with the Personal Data (Privacy) Ordinance (PDPO) of Hong
        Kong, which includes the following principles:
      </p>
      <ul>
        <li>
          <strong>Data Collection Principle:</strong> We collect personal data
          in a lawful and fair manner, for purposes directly related to our
          functions and activities.
        </li>
        <li>
          <strong>Data Accuracy Principle:</strong> We ensure that personal data
          is accurate and kept up to date.
        </li>
        <li>
          <strong>Data Retention Principle:</strong> We do not retain personal
          data longer than is necessary for the fulfillment of the purposes for
          which it is to be used.
        </li>
        <li>
          <strong>Data Use Principle:</strong> Personal data is used only for
          the purposes for which it was collected or for a directly related
          purpose, unless the data subject has given consent.
        </li>
        <li>
          <strong>Data Security Principle:</strong> We take all practicable
          steps to ensure that personal data is protected against unauthorized
          or accidental access, processing, erasure, loss, or use.
        </li>
        <li>
          <strong>Data Access and Correction Principle:</strong> Data subjects
          have the right to access and correct their personal data held by us.
        </li>
      </ul>
      <h2>Data Retention</h2>
      <p>
        We retain personal data for different periods depending on the nature of
        the data and the purposes for which it was collected. For example, we
        may retain:
      </p>
      <ul>
        <li>Contact information for marketing purposes until you opt-out.</li>
        <li>
          Transaction data for a minimum of 7 years to comply with legal and
          regulatory requirements.
        </li>
        <li>Survey responses for up to 2 years for research purposes.</li>
      </ul>
      <h2>Your Data Protection Rights</h2>
      <p>You have the following data protection rights:</p>
      <ul>
        <li>
          <strong>Right to Access:</strong> You have the right to request copies
          of your personal data.
        </li>
        <li>
          <strong>Right to Rectification:</strong> You have the right to request
          that we correct any information you believe is inaccurate.
        </li>
        <li>
          <strong>Right to Erasure:</strong> You have the right to request that
          we erase your personal data, under certain conditions.
        </li>
        <li>
          <strong>Right to Restrict Processing:</strong> You have the right to
          request that we restrict the processing of your personal data, under
          certain conditions.
        </li>
        <li>
          <strong>Right to Object to Processing:</strong> You have the right to
          object to our processing of your personal data, under certain
          conditions.
        </li>
        <li>
          <strong>Right to Data Portability:</strong> You have the right to
          request that we transfer the data that we have collected to another
          organization, or directly to you, under certain conditions.
        </li>
      </ul>
      <h2>Your Consent and Rights</h2>
      <ul>
        <li>
          Governing law and jurisdiction: Nothing in this Privacy Policy shall
          limit the rights of the data subject under the Ordinance. This Privacy
          Policy shall be governed by the laws of the Hong Kong Special
          Administrative Region. You agree to submit to the exclusive
          jurisdiction of the Hong Kong courts.
        </li>
      </ul>
      <h2>Changes to Privacy Policy</h2>
      <ul>
        <li>
          Any changes we may make to our privacy policy in the future will be
          posted on this page and, where appropriate, we will update you on the
          changes of our privacy policy by email.
        </li>
      </ul>
      <h5>
        Last Updated : 1 August 2024
      </h5>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsAndConditions",
};
</script>

<style scoped>
.terms-container {
  width: 85%;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
}

.title {
  text-align: center;
  margin-bottom: 20px;
  margin: 7% 0 5% 0;
  align-self: stretch;
  padding: 0 10%;
}

.content {
  font-size: 16px;
  padding: 0 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.content p {
  text-align: justify;
}

.content h2 {
  margin-top: 20px;
}

.content ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: justify;
}

.content li {
  margin-bottom: 10px;
  text-align: justify;
}
</style>
