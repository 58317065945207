<template>
    <div class="animation-wrapper-AddTeamMembers">
    <div class="animation-container-AddTeamMembers"></div>
</div>
  </template>
  
  <script>
  import lottie from 'lottie-web';
  import AddTeamMembers from './AddTeamMembers.json';

  
  export default {
    mounted() {
      this.loadAnimation();
    },
    methods: {
      loadAnimation() {
      const animation = lottie.loadAnimation({
        container: document.querySelector('.animation-container-AddTeamMembers'),
        animationData: AddTeamMembers,
        renderer: 'svg',
        loop: false,
        autoplay: true,
      });

      animation.addEventListener('DOMLoaded', () => {
        setTimeout(() => {
          animation.pause();
        }, 2000);
      });
    },
    }
  }
  </script>
  
  <style scoped>
  .animation-wrapper-AddTeamMembers {
    display: flex;
    /* border-radius: 50%; */
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: transparent;

  }
  .animation-container-AddTeamMembers {
    height: 100px;
    display: flex;
    align-items: center;
    position: relative;
    opacity: 0.5;
  }

  @media only screen and (max-width: 767px) {
    .animation-container-AddTeamMembers {
    height: 100px;
  }
  }
  </style>
  