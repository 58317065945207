<template>
  <div
    class="popup bg-b-lue"
    v-on:click.self="!feedbackStatus ? close() : closeSuccess()"
  >
    <div class="frame-35628" v-if="!feedbackStatus">
      <div class="frame-32">
        <div class="feedback">FEEDBACK</div>
        <div class="recent-articles">We’d love to hear from you!</div>
        <div class="here-s-what-we-ve-be">
          Give your feedback regarding credence order: {{ hashedOrderId }}
        </div>
      </div>
      <div class="frame-35859">
        <div class="frame-35860">
          <div class="here-s-what-we-ve-be2">Rate us here</div>
          <div class="stars" id="stars">
            <div
              class="star-wrapper"
              v-for="star in 5"
              :key="star"
              @click="setRating(star)"
              @mouseover="hoverRating = star"
              @mouseleave="hoverRating = null"
            >
              <span
                class="star"
                :class="{ filled: star <= (hoverRating || rating) }"
              >
                ★
              </span>
              <span
                class="text"
                :style="{ opacity: rating === star ? '1' : '0.5' }"
              >
                {{ labels[star - 1] }}
              </span>
            </div>
          </div>
        </div>
        <div class="frame-35861">
          <div
            class="here-s-what-we-ve-be5"
            v-if="rating != null && rating <= 3"
          >
            Anything that you think we should improve?<span
              style="color: #d20d3b"
              >*</span
            >
          </div>
          <div class="frame-35559" v-if="rating != null && rating <= 3">
            <textarea
              class="enter-email-address"
              placeholder="Enter Message"
              v-model="review"
            ></textarea>
          </div>
          <button
            class="submit-button"
            :disabled="rating === null || isSubmitting"
            :class="{ 'disabled-button': rating === null || isSubmitting }"
            @click="SubmitFeedback"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
    <div
      class="frame-35628"
      style="align-items: center; justify-content: center; position: relative"
      v-if="feedbackStatus"
    >
      <i
        class="fa-solid fa-xmark fa-lg"
        style="position: absolute; right: 20px; top: 20px; font-size: 23px;"
        v-if="isRetail && this.rating > 3"
        @click="closeSuccess()"
      ></i>
      <CompletedAnimationJSON />
      <div class="company-name-wrapper" style="margin-top: 10px">
        Thank you for giving us your valuable feedback!
      </div>
      <div class="company-name-wrapper" style="margin-top: 30px" v-if="isRetail && this.rating > 3">
        You can win more rewards by referring us to a friend
      </div>
      <div class="buttons-wrapper">
        <div
          class="refer-button"
          v-if="isRetail && this.rating > 3"
          @click="invite()"
        >
          Invite Friends
        </div>
        <div
          class="cancel-button"
          v-if="userType || isRetail && this.rating <= 3"
          @click="closeSuccess()"
        >
          <span>Close</span>
        </div>
      </div>
    </div>
  </div>
  <Toastify ref="toastContainer" />
</template>

<script>
import VueCookies from "vue-cookies";
import CompletedAnimationJSON from "@/assets/Animation/CompletedAnimation.vue";
import { Feedback } from "@/api/APIs";
import Toastify from "@/components/Toastify.vue";

export default {
  name: "ReviewsRating",
  components: {
    Toastify,
    CompletedAnimationJSON,
  },
  props: {
    orderId: {
      type: Number,
      required: true,
    },
    hashedOrderId: {
      type: String,
      required: true,
    },
  },
  beforeMount() {
    this.userToken = VueCookies.get("token");
  },
  computed: {
    feedbackStatus() {
      return this.feedbackSubmitted;
    },
    isRetail() {
      return VueCookies.get("user-type") === "RETAIL";
    },
    userType() {
      return ["HEAD", "MANAGER", "EXECUTIVE"].includes(
        VueCookies.get("user-type")
      );
    },
  },
  beforeUnmount() {},
  data() {
    return {
      rating: null,
      hoverRating: null,
      labels: ["Not Satisfied", "Not Bad", "Okay", "Good", "Excellent"],
      review: "",
      isSubmitting: false,
      feedbackSubmitted: false,
    };
  },
  methods: {
    closeSuccess() {
      this.$emit("submit");
      this.close();
    },
    invite() {
      this.rating = null;
      this.$emit("close");
      this.$emit("invite");
      this.feedbackSubmitted = false;
    },
    close() {
      this.rating = null;
      this.$emit("close");
      this.feedbackSubmitted = false;
    },
    setRating(star) {
      this.rating = star;
    },
    async SubmitFeedback() {
      if (this.rating === null) {
        return this.$refs.toastContainer.addToast(`Please select the rating`, {
          type: "error",
        });
      }
      if (this.isSubmitting) return;
      if (this.rating > 3) {
        this.review = "";
      }
      if (this.rating <= 3 && this.review == "") {
        return this.$refs.toastContainer.addToast(
          `Please provide a comment to help us improve`,
          {
            type: "error",
          }
        );
      }
      this.isSubmitting = true;
      const payload = {
        orderId: this.orderId,
        comment: this.review,
        rating: this.rating,
      };
      try {
        const response = await Feedback(payload);
        if (response.message == "SUCCESS") {
          this.feedbackSubmitted = true;
        } else {
          return this.$refs.toastContainer.addToast(
            `Something went wrong! Please try again later.`,
            { type: "error" }
          );
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          this.$refs.toastContainer.addToast(
            `Something went wrong! Please try again later.`,
            { type: "error" }
          );
        }
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>
<style scoped>
.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  z-index: 9999;
}
.frame-35628 {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  background: #ffffff;
  border-radius: 24px;
  width: 600px;
  box-shadow: 0px 24px 24px 0px rgba(17, 54, 90, 0.2);
  padding: 2rem 3rem;
}
.frame-32 {
  display: flex;
  flex-direction: column;
  gap: 9px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100%;
  position: relative;
}
.star-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  width: 18%;
  align-self: stretch;
}
.stars {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
}
.star {
  cursor: pointer;
  /* margin: 0 5px; */
  font-size: 40px;
  color: gray;
}
.star.filled {
  color: #ffcf55;
}
.text {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
}
.feedback {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  align-self: stretch;
}
.recent-articles {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-ExtraBold", sans-serif;
  font-size: 24px;
  font-weight: 800;
  position: relative;
  align-self: stretch;
}
.here-s-what-we-ve-be {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
  align-self: stretch;
}
.frame-35859 {
  display: flex;
  flex-direction: column;
  gap: 48px;
  align-items: center;
  justify-content: flex-start;
  /* flex-shrink: 0; */
  position: relative;
  width: 100%;
}
.company-name-wrapper {
  color: #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
}
.buttons-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.cancel-button {
  color: #11365a;
  border: 2px solid #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  border-radius: 16px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 50%;
}
.refer-button {
  color: white;
  background: #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  border-radius: 16px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 50%;
  border: none;
  outline: none;
}
.frame-35860 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.here-s-what-we-ve-be2 {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 16px;
  line-height: 180%;
  font-weight: 700;
  position: relative;
  align-self: stretch;
}
.vue-star-rating {
  display: flex;
  align-items: center;
  gap: 30px;
}
.submit-button {
  border: none;
  outline: none;
  height: 45px;
  width: 200px;
  background: #11365a;
  color: white;
  cursor: pointer;
  margin-top: 20px;
}
.submit-button:disabled,
.disabled-button {
  opacity: 0.5;
  cursor: not-allowed;
}
.frame-35858 {
  display: flex;
  flex-direction: row;
  gap: 27px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-35862 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 125px;
  position: relative;
}
.here-s-what-we-ve-be3 {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 16px;
  line-height: 180%;
  font-weight: 700;
  opacity: 0.20000000298023224;
  position: relative;
}
.frame-35863 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 125px;
  position: relative;
}
.frame-35864 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 125px;
  position: relative;
}
.frame-35865 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 125px;
  position: relative;
}
.frame-35866 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 125px;
  position: relative;
}
.here-s-what-we-ve-be4 {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 18px;
  line-height: 180%;
  font-weight: 700;
  position: relative;
}
.frame-35861 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  min-width: 80%;
}
.here-s-what-we-ve-be5 {
  color: #3e4855;
  text-align: left;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 18px;
  line-height: 180%;
  font-weight: 700;
  position: relative;
  align-self: stretch;
}
.frame-35559 {
  background: #ffffff;
  border-radius: 13px;
  border-style: solid;
  border-color: #11365a;
  border-width: 1px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100%;
  min-width: 150px;
  position: relative;
}
.message {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  letter-spacing: 0.01em;
  font-weight: 400;
  position: relative;
}
.navbar-instance {
  background: #ffffff !important;
  flex-shrink: 0 !important;
  width: 1440px !important;
}
.enter-email-address {
  color: #3e4855;
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  /* font-size: 14px; */
  font-weight: 400;
  position: relative;
  border-radius: 13px;
  border: none;
  outline: none;
  padding: 10px 17px 10px 17px;
  width: 100%;
  height: 145px;
  box-shadow: none;
  margin: 0;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .hero-section {
    gap: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
  .hero-section {
    width: 90%;
  }
}
</style>
