<template>
  <p>
    Hi <b>{{ $route.params.info.split('|')[0] }}</b>, the credits for user: <b>{{ $route.params.info.split('|')[1] }}</b> ({{ $route.params.info.split('|')[2] }}) have expired and needs to be replenished.<br/><u>Kindly select the number of credits you would like to add to the user's account and enter you token to proceed.</u>
    <br/>
    <label for="rm_token" style="font-weight:bold;">Your Token: </label>
    <input id="rm_token" type="text" v-model="rm_token" style="width:25%;" placeholder="Please enter your token" />
    &nbsp;<label for="credit_topup" style="font-weight:bold;">Number of Credits: </label>
    <select id="credit_topup" v-model="credit_topup" style="height:35px; border-radius:0.5em; box-shadow:0 1px 2px #aaa;" >
      <option value="1">1</option>
      <option value="5">5</option>
      <option value="10">10</option>
      <option value="20">20</option>
      <option value="30">30</option>
      <option value="40">40</option>
      <option value="50">50</option>
    </select>
    &nbsp;
    <button type="submit" v-on:click.prevent = "topUp()" >
      Submit
    </button>
  </p>
</template>

<script>

  export default {
    name: 'ReplenishCredits',
    props: [
      'rm_id',
      'user_id',
      'info',
    ],
    data() {
      return {
        'rm_token': null,
        'credit_topup': 1,
      }
    },
    methods: {
      async topUp(){
        
        this.$root.showLoader();

        if(this.rm_id && this.user_id && this.info && this.rm_token && this.credit_topup) {

          var options = {
            method: 'POST',
            headers: {'Content-type': 'application/json; charset=UTF-8'},
            body: JSON.stringify({
              rm_id: parseInt(this.rm_id),
              rm_token: this.rm_token,
              user_id: parseInt(this.user_id),
              credit_topup: parseInt(this.credit_topup),
            }),
          };

          try {
            let response = await fetch("http://"+process.env.VUE_APP_API_HOST+"/creditTopup", options);
            let results = await response.json();

            if(results && results.message == 'SUCCESS') {
              alert("User's account topped up successfully by "+this.credit_topup+" credits. The user have also been notified over email. Thanks.");
              window.location = '/';
            } else {
              alert('Server Error, kindly click on the email link again and enter correct token.');
            }
          } catch (error) {
            console.log(error);
            alert('Server Error, please try again later.');
          }
        } else if (!this.rm_token) {
          
          alert('Please provide your token to proceed.');
        } else {

          alert('Malformed link, kindly click on the email link again.');
        }

        this.$root.hideLoader();
      }
    }
  }

</script>