<template>
  <div class="popup loaderPopup" :style="{ left: userTokenAvailable && !mobile ? '18vw' : '0' }">
    <div class="popup-inner">
      <LoaderAnimation />
    </div>
  </div>
</template>

<script>
import LoaderAnimation from "@/assets/Animation/LoaderAnimation.vue";
import VueCookies from "vue-cookies";

export default {
  components: {
    LoaderAnimation,
  },
  data() {
    return {
      userToken: null,
      mobile: window.innerWidth <= 767,
    };
  },
  beforeMount() {
    this.userToken = VueCookies.get("token");
  },
  computed: {
    userTokenAvailable() {
      return this.userToken !== null;
    },
  },
};
</script>

<style>
.loaderPopup {
  z-index: 99999 !important;
}
</style>
