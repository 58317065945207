<template>
    <div class="animation-wrapper-search">
    <div class="animation-container-search"></div>
</div>
  </template>
  
  <script>
  import lottie from 'lottie-web';
  import NoData from './SearchAnimation.json';

  
  export default {
    mounted() {
      this.loadAnimation();
    },
    methods: {
      loadAnimation() {
        lottie.loadAnimation({
          container: document.querySelector('.animation-container-search'),
          animationData: NoData,
          renderer: 'svg',
          loop: true,
          autoplay: true,
        });
      },
    }
  }
  </script>
  
  <style scoped>
  .animation-wrapper-search {
    display: flex;
    /* border-radius: 50%; */
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: transparent;

  }
  .animation-container-search {
    height: 200px;
    display: flex;
    align-items: center;
    position: relative;
  }

  @media only screen and (max-width: 767px) {
    .animation-container-search {
    height: 140px;
  }
  }
  </style>
  