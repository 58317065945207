<template>
  <div v-if="open" class="drawer-overlay" @click.self="close">
    <div
      class="drawer-content"
      :style="{
        width: !showCTAButtons && !mobile ? '30%' : mobile ? '70%' : '25%',
        gap: !showCTAButtons ? '0' : '16px',
      }"
    >
      <slot name="header"></slot>
      <slot name="body"></slot>
      <button
        class="place-order"
        v-if="showCTAButtons"
        :disabled="cartCount === 0"
        :style="{ opacity: cartCount === 0 ? '0.5' : '1' }"
        @click="placeOrder"
      >
        Place Order
      </button>
      <button class="continue" v-if="showCTAButtons" @click="close">
        Close
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "WishlistDrawer",
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    cartCount: {
      type: Number,
      required: true,
    },
    showCTAButtons: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      mobile: window.innerWidth <= 767,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    placeOrder() {
      this.$emit("placeorder");
    },
  },
};
</script>

<style scoped>
.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.drawer-content {
  background: white;
  height: 94%;
  box-shadow: -10px 0px 36px 0px rgba(0, 0, 136, 0.16);
  position: relative;
  display: flex;
  padding: 40px 36px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
}

.continue {
  display: flex;
  /* padding: 17px 24px; */
  height: 54px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 13px;
  border: 2px solid #11365a;
  background: #fff;
  color: #11365a;
  width: 100%;
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.18px;
  text-transform: capitalize;
  cursor: pointer;
}
.place-order {
  display: flex;
  /* padding: 17px 24px; */
  height: 54px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  color: #11365a;
  border-radius: 13px;
  background: var(--Main-COlor, #26bbc2);
  width: 100%;
  border: none;
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.18px;
  text-transform: capitalize;
  cursor: pointer;
}

@media only screen and (max-width: 767px) { 
  .drawer-content {
    padding: 20px 18px;
    height: 85vh;
  }
  .place-order, .continue {
    height: 44px;
    font-size: 16px;
  }
  .drawer-overlay {
    align-items: flex-start;
    margin-top: 4.4rem;
  }
  .drawer-content { 
    box-shadow: -10px 10px 36px 0px rgba(0, 0, 136, 0.16);
  }
}
</style>
