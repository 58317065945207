<template>
  <div :class="['blogs-banner', { 'logged-in': userTokenAvailable && !isMobile, 'not-logged-in': !userTokenAvailable && isMobile }]">
    <div class="Customcontainer BlogsbannerContainer" :style="{paddingTop:   userTokenAvailable && '1rem'}">
      <section class="BlogsBanner">
        <div class="BlogsBanner_after container-fluid">
          <div class="BlogbannercontentBox">
            <span class="TitleTop">Blogs</span>
            <h2 class="Title" v-if="!userTokenAvailable">Welcome to Credence!</h2>
            <p class="TitleDetail">
              Expand your global reach by navigate cross-border trade seamlessly
              with us
            </p>
            <div class="BlogSearch" v-if="!userTokenAvailable">
              <form @submit.prevent="handleSubmit">
                <input
                  v-model.trim="email"
                  type="text"
                  class="form-control"
                  placeholder="Enter email address"
                  autocomplete="off"
                />
                <p v-if="errors.email && touched.email" class="error">
                  &nbsp; &nbsp;{{ errors.email }}
                </p>
                <button :disabled="isSubmitting" class="continue_btn">
                  {{ isSubmitting ? "Subscribing..." : "Subscribe" }}
                </button>
              </form>
            </div>
            <p class="TitleDetail">
              Insights, Trends, and Expertise: Navigate cross-border trade with
              Credence
            </p>
          </div>
        </div>
      </section>
      <Blogslist />
    </div>
    <Footer class="footer-instance" v-if="!userTokenAvailable"></Footer>
  </div>
</template>

<script>
import Footer from "@/components/HomePage/Footer/Footer.vue";
import Blogslist from "@/components/Blogs/BlogsList.vue";
import VueCookies from "vue-cookies";

export default {
  name: "BlogsBanner",
  components: {
    Blogslist,
    Footer,
  },
  data() {
    return {
      email: "",
      errors: {},
      touched: {},
      isSubmitting: false,
      userToken: null,
      isMobile: window.innerWidth < 1023,
    };
  },
  beforeMount() {
    this.userToken = VueCookies.get("token");
  },
  computed: {
    userTokenAvailable() {
      return this.userToken !== null;
    },
  },
  methods: {},
};
</script>

<style scoped>
.blogs-banner {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.logged-in {
  margin-left: 18vw;
}

.not-logged-in {
  width: 100%;
}
.Customcontainer {
  width: 90%;
  margin-top: 70px;
  margin-bottom: 50px;
}
.BlogsBanner {
  background: url(@/assets/sampleBg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  border-radius: 18px;
  overflow: hidden;
  min-height: 200px;
}
.BlogsBanner_after {
  height: 100%;
  width: 100%;
  position: relative;
  /* left: 0;
  top: 0; */
  padding: 4rem 0;
  background: linear-gradient(90deg, #11365abc, #11365a57 50.31%, #11365a00);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.BlogbannercontentBox {
  width: 85%;
  /* max-width: 75rem; */
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 55px;
}
.TitleTop {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1;
  font-family: "Montserrat-SemiBold", sans-serif;
}
.Title {
  color: white;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.44;
  font-family: "Montserrat-SemiBold", sans-serif;
}
.TitleDetail {
  font-size: 18px;
  font-weight: 500;
  font-family: "Montserrat-SemiBold", sans-serif;
  margin: 0;
  line-height: 35px;
}
.form-control {
  border: none;
  outline: none;
}
.continue_btn {
  background-color: #11365a;
  color: white;
  padding: 10px 15px;
  outline: none;
  border: none;
  box-shadow: 0 24px 24px 0 rgba(17, 54, 90, 0.2);
  border-radius: 50px;
  height: 100%;
}
.BlogSearch {
  padding: 2px;
  border-radius: 50px;
  background-color: white;
  overflow: hidden;
  width: 40%;
}
input[type="text"] {
  margin: 0;
  box-shadow: none;
  height: 100%;
  width: 60%;
}
form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.footer-instance {
  flex-shrink: 0 !important;
  width: 100% !important;
}

@media only screen and (max-width: 767px) {
  .BlogsBanner_after {
    padding: 1rem 0;
  }
  .TitleTop {
    font-size: 16px;
  }
  .Title {
    font-size: 16px;
  }
  .TitleDetail {
    font-size: 12px;
    text-align: left;
  }
  .BlogSearch {
    width: 70%;
  }
  .continue_btn {
    padding: 5px 8px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
  .BlogsBanner_after {
    padding: 2rem 0;
  }
  .TitleTop {
    font-size: 20px;
  }
  .Title {
    font-size: 25px;
  }
  .TitleDetail {
    font-size: 16px;
  }
  .BlogSearch {
    width: 55%;
  }
}
@media only screen and (min-width: 1156px) {
}
</style>
