<template>
  <div
    id="contact-form"
    :class="[
      'contact-container',
      {
        'logged-in': userTokenAvailable && !isMobile,
        'not-logged-in': !userTokenAvailable && isMobile,
      },
    ]"
    :style="{ height: isMobile ? '88vh' : '93vh' }"
  >
    <div class="contactUs">
      <div class="contact-details">
        <span class="title">Contact Us</span>
        <p class="description">
          Please tell us about your specific information requirements by filling
          out the details here and someone will be in touch with you shortly.
        </p>
        <p
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;
            text-align: left;
          "
          class="description"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 6C11.2583 6 10.5333 6.21993 9.91661 6.63199C9.29993 7.04404 8.81928 7.62971 8.53545 8.31494C8.25162 9.00016 8.17736 9.75416 8.32205 10.4816C8.46675 11.209 8.8239 11.8772 9.34835 12.4017C9.8728 12.9261 10.541 13.2833 11.2684 13.4279C11.9958 13.5726 12.7498 13.4984 13.4351 13.2145C14.1203 12.9307 14.706 12.4501 15.118 11.8334C15.5301 11.2167 15.75 10.4917 15.75 9.75C15.75 8.75544 15.3549 7.80161 14.6517 7.09835C13.9484 6.39509 12.9946 6 12 6ZM12 12C11.555 12 11.12 11.868 10.75 11.6208C10.38 11.3736 10.0916 11.0222 9.92127 10.611C9.75097 10.1999 9.70642 9.7475 9.79323 9.31105C9.88005 8.87459 10.0943 8.47368 10.409 8.15901C10.7237 7.84434 11.1246 7.63005 11.561 7.54323C11.9975 7.45642 12.4499 7.50097 12.861 7.67127C13.2722 7.84157 13.6236 8.12996 13.8708 8.49997C14.118 8.86998 14.25 9.30499 14.25 9.75C14.25 10.3467 14.0129 10.919 13.591 11.341C13.169 11.7629 12.5967 12 12 12ZM12 1.5C9.81273 1.50248 7.71575 2.37247 6.16911 3.91911C4.62247 5.46575 3.75248 7.56273 3.75 9.75C3.75 12.6938 5.11031 15.8138 7.6875 18.7734C8.84552 20.1108 10.1489 21.3151 11.5734 22.3641C11.6995 22.4524 11.8498 22.4998 12.0037 22.4998C12.1577 22.4998 12.308 22.4524 12.4341 22.3641C13.856 21.3147 15.1568 20.1104 16.3125 18.7734C18.8859 15.8138 20.25 12.6938 20.25 9.75C20.2475 7.56273 19.3775 5.46575 17.8309 3.91911C16.2843 2.37247 14.1873 1.50248 12 1.5ZM12 20.8125C10.4503 19.5938 5.25 15.1172 5.25 9.75C5.25 7.95979 5.96116 6.2429 7.22703 4.97703C8.4929 3.71116 10.2098 3 12 3C13.7902 3 15.5071 3.71116 16.773 4.97703C18.0388 6.2429 18.75 7.95979 18.75 9.75C18.75 15.1153 13.5497 19.5938 12 20.8125Z"
              fill="#11365A"
            />
          </svg>
          Room 8, 5th Floor, Cyberport 3 100 Cyberport Road, Hong Kong
        </p>
        <p
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;
            text-align: left;
          "
          class="description"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.8472 14.8554L16.4306 12.8764L16.4184 12.8707C16.1892 12.7727 15.939 12.7333 15.6907 12.7562C15.4424 12.7792 15.2037 12.8636 14.9963 13.002C14.9718 13.0181 14.9484 13.0357 14.9259 13.0545L12.6441 14.9998C11.1984 14.2976 9.70595 12.8164 9.00376 11.3895L10.9519 9.07294C10.9706 9.0495 10.9884 9.02606 11.0053 9.00075C11.1407 8.79384 11.2229 8.55667 11.2445 8.31035C11.2661 8.06402 11.2264 7.81618 11.1291 7.58887V7.57762L9.14438 3.15356C9.0157 2.85662 8.79444 2.60926 8.51362 2.44841C8.2328 2.28756 7.9075 2.22184 7.58626 2.26106C6.31592 2.42822 5.14986 3.05209 4.30588 4.01615C3.4619 4.98021 2.99771 6.21852 3.00001 7.49981C3.00001 14.9436 9.05626 20.9998 16.5 20.9998C17.7813 21.0021 19.0196 20.5379 19.9837 19.6939C20.9477 18.85 21.5716 17.6839 21.7388 16.4136C21.7781 16.0924 21.7125 15.7672 21.5518 15.4864C21.3911 15.2056 21.144 14.9843 20.8472 14.8554ZM16.5 19.4998C13.3185 19.4963 10.2682 18.2309 8.01856 15.9813C5.76888 13.7316 4.50348 10.6813 4.50001 7.49981C4.49648 6.58433 4.82631 5.69887 5.42789 5.00879C6.02947 4.3187 6.86167 3.87118 7.76907 3.74981C7.7687 3.75355 7.7687 3.75732 7.76907 3.76106L9.73782 8.16731L7.80001 10.4867C7.78034 10.5093 7.76247 10.5335 7.74657 10.5589C7.60549 10.7754 7.52273 11.0246 7.5063 11.2825C7.48988 11.5404 7.54035 11.7981 7.65282 12.0307C8.5022 13.7679 10.2525 15.5051 12.0084 16.3536C12.2428 16.465 12.502 16.5137 12.7608 16.495C13.0196 16.4762 13.2692 16.3907 13.485 16.2467C13.5091 16.2305 13.5322 16.2129 13.5544 16.1942L15.8334 14.2498L20.2397 16.2232C20.2397 16.2232 20.2472 16.2232 20.25 16.2232C20.1301 17.1319 19.6833 17.9658 18.9931 18.5689C18.3028 19.172 17.4166 19.5029 16.5 19.4998Z"
              fill="#11365A"
            />
          </svg>
          +852-31663925, +852-81752593
        </p>
        <p
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;
            text-align: left;
          "
          class="description"
        >
          <svg
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.4163 6.37562L10.4163 0.375624C10.293 0.293395 10.1482 0.249512 10 0.249512C9.85184 0.249512 9.707 0.293395 9.58375 0.375624L0.58375 6.37562C0.48101 6.44417 0.396789 6.53703 0.338565 6.64595C0.280342 6.75488 0.24992 6.87649 0.25 7V16.75C0.25 17.1478 0.408035 17.5294 0.68934 17.8107C0.970644 18.092 1.35218 18.25 1.75 18.25H18.25C18.6478 18.25 19.0294 18.092 19.3107 17.8107C19.592 17.5294 19.75 17.1478 19.75 16.75V7C19.7501 6.87649 19.7197 6.75488 19.6614 6.64595C19.6032 6.53703 19.519 6.44417 19.4163 6.37562ZM7.0675 12.25L1.75 16V8.45594L7.0675 12.25ZM8.60219 13H11.3978L16.7069 16.75H3.29312L8.60219 13ZM12.9325 12.25L18.25 8.45594V16L12.9325 12.25ZM10 1.90094L17.6791 7.02062L11.3978 11.5H8.60406L2.32281 7.02062L10 1.90094Z"
              fill="#11365A"
            />
          </svg>

          support@credencedata.com
        </p>
      </div>
      <div class="contact-form-wrapper">
        <form @submit.prevent="submitForm" class="contact-form">
          <div class="input-group full" v-if="!userTokenAvailable">
            <input
              type="text"
              v-model="form.companyName"
              placeholder="Company name*"
              required
            />
          </div>
          <div class="input-group full" v-if="!userTokenAvailable">
            <input
              type="text"
              v-model="form.jobFunction"
              placeholder="Your job function in the organisation*"
              required
            />
          </div>
          <div class="input-group" v-if="!userTokenAvailable">
            <input
              type="text"
              v-model="form.firstName"
              placeholder="First name*"
              required
            />
          </div>
          <div class="input-group" v-if="!userTokenAvailable">
            <input
              type="text"
              v-model="form.lastName"
              placeholder="Last name*"
              required
            />
          </div>
          <div class="input-group" v-if="!userTokenAvailable">
            <input
              type="email"
              v-model="form.businessEmail"
              placeholder="Business email*"
              required
            />
          </div>
          <div
            class="input-group"
            style="
              flex-direction: row;
              width: 100%;
              justify-content: space-between;
              background-color: white;
              border-radius: 13px;
              overflow: hidden;
              border: 1px solid #ccc;
            "
            v-if="!userTokenAvailable"
          >
            <select v-model="selectedCountryCode" class="country-dropdown">
              <option value="" disabled>Select a country</option>
              <option
                v-for="country in countries"
                :value="country"
                :key="country.code"
                class="country-option"
              >
                {{ country.name }} - (+{{ getFirstDialCode(country.dialCode) }})
              </option>
            </select>
            <input
              type="tel"
              v-model="form.phone"
              placeholder="Phone*"
              style="width: 66%; border: none !important"
              inputmode="tel"
              autocomplete="tel"
              required
              @input="formatPhoneNumber"
              pattern="[0-9]*"
            />
            <VPhoneNumber label="Phone Number" v-model="phoneNumber" />
          </div>
          <div class="input-group" v-if="!userTokenAvailable">
            <input
              type="text"
              v-model="form.city"
              placeholder="City*"
              required
            />
          </div>
          <div
            class="input-group frame-6"
            style="justify-content: center; position: relative"
            v-if="!userTokenAvailable"
          >
            <input
              type="text"
              placeholder="Country/Region*"
              :value="selectedCountry.name"
              @input="updateSelectedCountry"
              class="input-group"
              style="
                border: none;
                margin: 0;
                width: 100%;
                width: 100%;
                padding: 0 0 0 10px;
                height: 100% !important;
                background: transparent;
                z-index: 1;
              "
              ref="searchInput"
              @click="showResults"
              required
            />
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              :class="{
                'rotate-180': showResultsFlag,
                'rotate-0': !showResultsFlag,
              }"
              style="position: absolute; right: 0"
              @click="showResults"
            >
              <path
                d="M6 9L9.5 13L13 9"
                stroke="black"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div
              class="country-results"
              v-if="showResultsFlag"
              ref="countryResults"
            >
              <div
                v-for="country in filteredCountries"
                :key="country.id"
                @click="selectCountry(country)"
              >
                <template v-if="isWindows()"
                  ><img
                    :src="country.flagImage"
                    alt="Flag"
                    class="flag-image"
                  />
                  {{ country.name }}</template
                >
                <template v-else
                  >{{ country.flagEmoji }} {{ country.name }}</template
                >
              </div>
            </div>
          </div>
          <div class="input-group full">
            <textarea
              v-model="form.message"
              :required="userTokenAvailable"
              :placeholder="
                !userTokenAvailable ? 'Message (Optional)' : 'Message'
              "
            ></textarea>
          </div>
          <div
            class="input-group full checkbox-group"
            :style="{ display: userTokenAvailable ? 'none' : 'flex' }"
            v-for="(consent, index) in form.consents"
            :key="index"
          >
            <input
              type="checkbox"
              v-model="consent.value"
              :id="consent.key + '-consent'"
              :required="consent.key === 'data' && !userTokenAvailable"
            />
            <label :for="consent.key + '-consent'">{{ consent.title }}</label>
          </div>
          <div class="buttons">
            <button
              type="submit"
              class="btn demo-btn"
              :style="{ opacity: loading ? '0.5' : '1' }"
              :disabled="loading"
            >
              {{ loading ? "Submitting..." : "Submit" }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <Footer v-if="!userTokenAvailable" />
  </div>
  <Toastify ref="toastContainer" />
  <ContactFormSubmission
    v-if="showContactFormSuccess"
    :supportReqNumber="supportReqNumber"
    @close="hideContactFormSubmission()"
  />
</template>
<script>
import { AllCountries, ContactUs } from "../api/APIs";
import Toastify from "@/components/Toastify.vue";
import Footer from "@/components/HomePage/Footer/Footer.vue";
import { useReCaptcha } from "vue-recaptcha-v3";
import VueCookies from "vue-cookies";
import ContactFormSubmission from "@/views/Modals/ContactFormSubmission.vue";

export default {
  name: "ContactUs",
  components: {
    Toastify,
    Footer,
    ContactFormSubmission,
  },
  data() {
    return {
      form: {
        userToken: null,
        companyName: "",
        firstName: "",
        lastName: "",
        businessEmail: "",
        phone: "",
        jobFunction: "",
        city: "",
        countryId: "",
        message: "",
        consents: [
          {
            title:
              "I would like to be contacted by CredenceData about relevant emerging market content, services and offers in the future.",
            value: false,
            key: "contact",
          },
          {
            title:
              "I agree to allow CredenceData to store and process my personal data.*",
            value: false,
            key: "data",
          },
        ],
      },
      loading: false,
      selectedCountry: {},
      selectedCountryCode: {},
      countries: [],
      filteredCountries: [],
      showResultsFlag: false,
      isMobile: window.innerWidth < 1023,
      supportReqNumber: "",
      showContactFormSuccess: false,
      executeRecaptcha: null,
      recaptchaLoaded: null,
      siteKey: "6Ldwj_cpAAAAAMFNhKnnTgLeT7yboKC_Nv8zZ0cx",
    };
  },
  created() {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha({
      siteKey: this.siteKey,
      size: "invisible",
      badge: "inline",
    });

    this.executeRecaptcha = executeRecaptcha;
    this.recaptchaLoaded = recaptchaLoaded;
  },
  beforeMount() {
    this.userToken = VueCookies.get("token");
    const shouldRefresh = localStorage.getItem("shouldRefresh");

    if (shouldRefresh) {
      localStorage.removeItem("shouldRefresh");
      window.location.reload(true);
    } else {
      localStorage.setItem("shouldRefresh", "true");
    }
  },
  mounted() {
    this.fetchCountryList();
    window.addEventListener("resize", this.handleResponsive);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResponsive);
  },
  computed: {
    userTokenAvailable() {
      return this.userToken !== null;
    },
  },
  methods: {
    async recaptcha() {
      await this.recaptchaLoaded();
      const token = await this.executeRecaptcha("login");
      return token;
    },
    hideContactFormSubmission() {
      this.supportReqNumber = "";
      this.showContactFormSuccess = false;
    },
    handleResponsive() {
      this.isMobile = window.innerWidth < 1023;
    },
    updateSelectedCountry(event) {
      this.selectedCountry = {
        ...this.selectedCountry,
        name: event.target.value,
      };
      this.filterCountries();
    },
    isWindows() {
      return /Windows/.test(window.navigator.userAgent);
    },
    getFirstDialCode(dialCodeString) {
      return dialCodeString.split(",")[0];
    },
    formatPhoneNumber() {
      this.form.phone = this.form.phone.replace(/\D/g, "");
    },
    async submitForm() {
      this.loading = true;
      const token = await this.recaptcha();
      if (!token) {
        this.loading = false;
        return this.$refs.toastContainer.addToast(
          "Something went wrong! Please try again later.",
          { type: "error" }
        );
      }

      const firstDialCode = this.userTokenAvailable
        ? null
        : this.getFirstDialCode(this.selectedCountryCode.dialCode);

      if (!this.userTokenAvailable) {
        const phoneNumberLengths = this.selectedCountryCode.phoneLength
          .split(",")
          .map((length) => parseInt(length.trim()));

        if (!phoneNumberLengths.includes(this.form.phone.length)) {
          this.loading = false;
          return this.$refs.toastContainer.addToast(
            `Phone number length should be ${this.selectedCountryCode.phoneLength} digits`,
            { type: "error" }
          );
        }
      }

      const payload2 = {
        businessEmail: VueCookies.get("email"),
        message: this.form.message,
        consents: this.form.consents,
      };

      const payload = {
        companyName: this.form.companyName,
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        businessEmail: this.form.businessEmail,
        phone: `+${firstDialCode}-${this.form.phone}`,
        jobFunction: this.form.jobFunction,
        city: this.form.city,
        countryId: this.selectedCountry.id,
        message: this.form.message,
        consents: this.form.consents,
      };
      try {
        const response = await ContactUs(
          this.userTokenAvailable ? payload2 : payload
        );
        if (response.message == "SUCCESS") {
          this.clearForm();
          this.supportReqNumber = response?.supportReqNumber;
          this.showContactFormSuccess = true;
        } else {
          return this.$refs.toastContainer.addToast(
            `Something went wrong! Please try again later.`,
            { type: "error" }
          );
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          this.$refs.toastContainer.addToast(
            `Something went wrong! Please try again later.`,
            { type: "error" }
          );
        }
      } finally {
        this.loading = false;
      }
    },
    clearForm() {
      this.form.companyName = "";
      this.form.firstName = "";
      this.form.lastName = "";
      this.form.businessEmail = "";
      this.form.phone = "";
      this.form.jobFunction = "";
      this.form.city = "";
      this.form.message = "";
      this.form.consents = [
        {
          title:
            "I would like to be contacted by CredenceData about relevant emerging market content, services and offers in the future.",
          value: false,
          key: "contact",
        },
        {
          title:
            "I agree to allow CredenceData to store and process my personal data.*",
          value: false,
          key: "data",
        },
      ];
    },
    async fetchCountryList() {
      try {
        const response = await AllCountries(true);
        this.countries = await response.allCountries;
      } catch (error) {
        this.loginError = "Error occurred during login.";
        console.error(error);
      }
    },
    filterCountries() {
      this.showResultsFlag = true;
      this.filteredCountries = this.countries.filter((country) =>
        country.name.toLowerCase().includes(this.selectedCountry?.name)
      );
    },
    selectCountry(country) {
      this.selectedCountry = country;
      this.filteredCountries = [];
    },
    showResults() {
      this.showResultsFlag = true;
      this.filteredCountries = this.countries;
      document.addEventListener("click", this.closeResultsOnClickOutside);
    },
    closeResultsOnClickOutside(event) {
      const searchInput = this.$refs.searchInput;
      const countryResults = this.$refs.countryResults;
      if (
        searchInput &&
        !searchInput.contains(event.target) &&
        countryResults &&
        !countryResults.contains(event.target)
      ) {
        this.showResultsFlag = false;
        document.removeEventListener("click", this.closeResultsOnClickOutside);
      }
    },
  },
};
</script>
<style scoped>
.contact-container {
  padding: 5% 0 0 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7%;
}
.logged-in {
  margin-left: 18vw;
  height: 95vh;
  padding-top: 115px;
}
.not-logged-in {
  width: 100%;
}
.contactUs {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 7%;
  width: 90%;
  margin-bottom: 4rem;
}
.description {
  text-align: justify;
  font-family: "Montserrat-Medium", sans-serif;
  line-height: 2;
}
.contact-details {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.title {
  font-size: 46px;
  font-family: "Montserrat-Bold", sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
}
.contact-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  width: 30vw;
}
.input-group {
  display: flex;
  flex-direction: column;
}
.input-group.full {
  grid-column: span 2;
}
.input-group label {
  margin-bottom: 5px;
}
.input-group input,
.input-group select,
.input-group textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 13px;
  height: auto !important;
  box-shadow: none;
  outline: none;
}
.input-group textarea {
  height: 150px !important;
  resize: vertical;
}
.checkbox-group {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.checkbox-group label {
  font-family: "Montserrat-Bold", sans-serif;
  text-align: left;
  margin-left: 10px;
  /* line-height: 2; */
}

.buttons {
  grid-column: span 2;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  box-shadow: none;
}
.btn {
  padding: 10px 20px;
  width: 100%;
  /* border: 1px solid #ccc; */
  border: none;
  cursor: pointer;
  background-color: #11365a;
}
.country-dropdown {
  width: 28%;
  outline: none !important;
  border: none !important;
}

.country-results {
  width: 100%;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  background-color: white;
  border-radius: 15px;
  overflow-y: auto;
  top: 110%;
  z-index: 9999;
  box-shadow: 0px 16px 16px 0px rgba(20, 45, 77, 0.1);
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.country-results div {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding: 5px 0 5px 20px;
  text-align: left;
  font-size: 14px;
}
.country-results div:hover {
  background-color: #dee9f2;
}
.flag-image {
  width: 20px;
  height: 15px;
}
.rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}
.rotate-0 {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}
.frame-6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 13px;
  height: 35.5px !important;
  margin: 8px 0;
  padding: 0;
}
@media only screen and (max-width: 767px) {
  .contactUs {
    flex-direction: column;
  }
  .title {
    font-size: x-large;
    text-align: center;
    width: 100%;
  }
  .contact-details {
    width: 100%;
  }
  .description {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
  }
  .contact-details p {
    font-size: 16px;
    text-align: left;
    margin-block-start: 0.4em;
    margin-block-end: 0.4em;
  }
  .checkbox-group label {
    font-size: 16px;
  }
  .contact-form,
  .contact-form-wrapper {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
  .title {
    font-size: x-large;
  }
  .description {
    font-size: 16px;
    line-height: 1.5;
  }
  .contact-details p {
    font-size: 16px;
  }
  .checkbox-group label {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1156px) {
}
</style>
