<template>
  <div class="popup bg-b-lue" v-on:click.self="hideCreateTeamModal()">
    <div class="frame-35628">
      <div class="title">Name your Team</div>
      <input
        type="name"
        placeholder="Ex - TeamData Experts"
        class="team-name"
        autocomplete="off"
        id="teamName"
        v-model="teamName"
        for="teamName"
      />
      <div class="benefits-wrapper">
        <div class="benefits-title">What your team will get:</div>
        <div class="benefits-list">
          <div
            class="benefits"
            v-for="(benefit, index) in benefits"
            :key="index"
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 12"
                fill="none"
              >
                <path
                  d="M5.4343 11.6971L0.234305 6.49705C-0.0781016 6.18465 -0.0781016 5.67812 0.234305 5.36568L1.36565 4.2343C1.67805 3.92187 2.18462 3.92187 2.49702 4.2343L5.99999 7.73724L13.503 0.234305C13.8154 -0.0781016 14.3219 -0.0781016 14.6343 0.234305L15.7657 1.36568C16.0781 1.67809 16.0781 2.18462 15.7657 2.49705L6.56568 11.6971C6.25324 12.0095 5.74671 12.0095 5.4343 11.6971Z"
                  fill="#11365A"
                />
              </svg>
            </div>
            <span>
              {{ benefit }}
            </span>
          </div>
        </div>
        <div
          :class="['create-button', { disabled: isCreateButtonDisabled }]"
          @click="isCreateButtonDisabled ? null : createTeam()"
        >
          <span>Create</span>
        </div>
        <div class="cancel-button" @click="hideCreateTeamModal()">
          <span>Cancel</span>
        </div>
      </div>
    </div>
  </div>
  <Toastify ref="toastContainer" />
</template>
<script>
import { CreateTeam } from "@/api/APIs";
import Toastify from "@/components/Toastify.vue";
import VueCookies from "vue-cookies";

export default {
  name: "CreateTeamModal",
  props: {
    hideCreateTeamModal: {
      type: Function,
      required: true,
    },
    inviteMembersModal: {
      type: Function,
      required: true,
    },
  },
  components: {
    Toastify,
  },
  data() {
    return {
      teamName: "",
      benefits: [
        "You can add members in the same organization",
        "All team members can order reports",
        "The valid database is available for everyone",
      ],
    };
  },
  computed: {
    isCreateButtonDisabled() {
      return this.teamName.trim().length < 3;
    },
  },
  methods: {
    async createTeam() {
      try {
        let payload = {
          name: this.teamName,
        };
        const response = await CreateTeam(payload);
        if (response?.message == "SUCCESS") {
          VueCookies.set("teamID", response?.data?.id);
          this.$refs.toastContainer.addToast(`Team created successfuly!`, {
            type: "success",
          });
          VueCookies.set("teamName", this.teamName);
          this.inviteMembersModal();
        } else {
          this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
  },
};
</script>
<style scoped>
.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  z-index: 9999;
}
.frame-35628 {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  justify-content: flex-start;
  max-width: 420px;
  position: relative;
  background: #ffffff;
  border-radius: 24px;
  min-height: 384px;
  box-shadow: 0px 24px 24px 0px rgba(17, 54, 90, 0.2);
  padding: 2rem 3rem;
}
.title {
  color: var(--Dark-Blue, #11365a);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-self: stretch;
  text-align: left;
}
.team-name {
  padding: 12px 24px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #11365a;
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.16px;
  outline: none;
}
.benefits-wrapper,
.benefits-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  gap: 18px;
}
.benefits-title {
  color: var(--Text-Mid, #3e4855);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.18px;
}
.benefits {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  gap: 10px;
}
.create-button {
  display: flex;
  color: white;
  font-family: "Montserrat-Regular", sans-serif;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  border-radius: 16px;
  padding: 12px 26px;
  justify-content: center;
  align-items: center;
  background: #11365a;
  cursor: pointer;
  align-self: stretch;
  margin-top: 20px;
}
.create-button.disabled {
  opacity: 0.4;
  background: var(--Dark-Blue, #11365a);
}
.cancel-button {
  color: #11365a;
  border: 2px solid #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  border-radius: 16px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-self: stretch;
}
@media only screen and (max-width: 767px) {
  .title {
    font-size: 18px;
  }
  .frame-35628 {
    gap: 15px;
    padding: 2rem 1.5rem;
    max-width: 95vw;
  }
  .benefits-title {
    font-size: 16px;
  }
  .benefits div svg {
    width: 14px;
    height: 14px;
  }
  .create-button {
    padding: 8px 26px;
  }
  .cancel-button {
    padding: 6px 24px;
  }
  .team-name {
    padding: 7px 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
}
@media only screen and (min-width: 1156px) {
}
</style>
