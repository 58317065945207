<template>
  <div
    id="userMenu"
    v-if="
      $route.path !== '/login' &&
      $route.path !== '/whatsapp/termsandconditions' &&
      !userTokenAvailable
    "
    :class="appClass"
  >
    <div class="frame-35582">
      <div class="frame-7">
        <router-link
          to="/"
          class="router-link-no-hover"
          aria-label="Credence Home"
        >
          <div class="frame-1">
            <img class="image-2" src="@/assets/logo.webp" alt="Credence" />
          </div>
        </router-link>
        <div class="frame-4" v-if="!userTokenAvailable && !showMobileMenu">
          <div class="partnership" @click="redirectToHome">Home</div>
        </div>
        <div class="frame-5" v-if="!userTokenAvailable && !showMobileMenu">
          <div class="blogs" @click="redirectToProducts">Product</div>
        </div>
        <!-- <div class="frame-72" v-if="!userTokenAvailable && !showMobileMenu">
          <div class="blogs" @click="redirectToBlogs">Blogs</div>
        </div> -->
        <div class="frame-9" v-if="!userTokenAvailable && !showMobileMenu">
          <div class="about" @click="redirectToAboutUs">About</div>
        </div>
      </div>
      <div v-if="showMobileMenu" class="hamburger-menu" @click="toggleDrawer">
        <div class="iconly-sharp-menu-instance">
          <svg
            width="30"
            height="30"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.625 18C29.625 17.3787 29.1213 16.875 28.5 16.875H7.5C6.87868 16.875 6.375 17.3787 6.375 18C6.375 18.6213 6.87868 19.125 7.5 19.125H28.5C29.1213 19.125 29.625 18.6213 29.625 18Z"
              fill="black"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.625 10.5C29.625 9.87868 29.1213 9.375 28.5 9.375H7.5C6.87868 9.375 6.375 9.87868 6.375 10.5C6.375 11.1213 6.87868 11.625 7.5 11.625H28.5C29.1213 11.625 29.625 11.1213 29.625 10.5Z"
              fill="black"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.625 25.5C29.625 24.8787 29.1213 24.375 28.5 24.375H7.5C6.87868 24.375 6.375 24.8787 6.375 25.5C6.375 26.1213 6.87868 26.625 7.5 26.625H28.5C29.1213 26.625 29.625 26.1213 29.625 25.5Z"
              fill="black"
            />
          </svg>
        </div>
      </div>

      <!-- Drawer Navigation -->
      <div v-if="showDrawer" class="bg-b-lue" v-on:click.self="toggleDrawer">
        <transition name="slide">
          <div class="drawer-navigation">
            <div class="drawer-item" @click="redirectToHome">
              <div class="solutions" style="text-align: left">Home</div>
            </div>
            <div
              class="drawer-item"
              v-if="!userTokenAvailable"
              @click="redirectToProducts"
            >
              <div class="solutions" style="text-align: left">Product</div>
            </div>
            <div
              class="drawer-item"
              v-if="!userTokenAvailable"
              @click="redirectToAboutUs"
            >
              <div class="solutions" style="text-align: left">About</div>
            </div>
            <div
              class="drawer-item"
              v-if="!userTokenAvailable"
              @click="showLogin('Login')"
            >
              <div class="partnership" style="text-align: left">Login</div>
            </div>
            <div
              class="drawer-item"
              v-if="!userTokenAvailable"
              @click="showLogin('SignUp')"
            >
              <div class="blogs" style="text-align: left">Sign Up</div>
            </div>
          </div>
        </transition>
      </div>
      <div v-if="!showMobileMenu" class="frame-82">
        <ButtonProperty1SecondaryHoverFalse
          v-if="enableLogin && userToken == null"
          property1="secondary"
          class="button-instance"
          style="cursor: pointer"
          @click="showLogin('SignUp')"
        ></ButtonProperty1SecondaryHoverFalse>

        <ButtonProperty1PrimaryHoverFalse
          v-if="enableLogin && userToken == null"
          class="button-instance"
          style="cursor: pointer"
          @click="showLogin('Login')"
        ></ButtonProperty1PrimaryHoverFalse>
      </div>
    </div>
  </div>
  <div
    id="userMenu"
    v-if="userTokenAvailable && showMobileMenu"
    :class="appClass"
  >
    <div class="frame-355821">
      <div class="navbar-left">
        <div
          class="menu-wrapper"
          @click="
            this.drawerOpen = false;
            this.showDrawer = !this.showDrawer;
          "
          :class="{ active: this.showDrawer }"
        >
          <div class="menu-bar one"></div>
          <div class="menu-bar two"></div>
          <div class="menu-bar three"></div>
        </div>
        <div class="frame-7">
          <router-link
            to="/"
            @click="navBarCkicked()"
            class="router-link-no-hover"
          >
            <div class="frame-1">
              <img class="image-2" src="@/assets/logo.webp" alt="Credence" />
            </div>
          </router-link>
        </div>
      </div>
      <div
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 15px;
        "
      >
        <router-link
          to="/rewards"
          v-if="userTokenAvailable && isRetail"
          style="padding: 0"
        >
          <img
            src="@/assets/Icons/reward1.webp"
            alt="Credence My Orders"
            class="icon-image reward"
            @click="
              this.drawerOpen = false;
              this.showDrawer = false;
            "
          />
        </router-link>
        <div class="cart-icon-wrapper" v-if="!isRM && !isAdmin">
          <Dropdown
            :triggers="[]"
            :shown="isCheckoutOpen"
            :autoHide="true"
            style="display: flex"
          >
            <img
              src="@/assets/Icons/cart.webp"
              alt="Credence My Orders"
              class="icon-image"
              style="cursor: pointer"
              @click="toggleWishlist()"
            />
            <div class="cart-count" v-if="cartCount > 0">{{ cartCount }}</div>
            <template #popper>
              <div class="tooltip-content">
                <CartAnimation />
                <div class="tooltip-message">Proceed to checkout</div>
              </div>
            </template>
          </Dropdown>
        </div>
      </div>
      <Drawer
        :open="drawerOpen"
        @close="toggleWishlist"
        @placeorder="toggleCheckout"
        :cartCount="updatedCartList.length"
        :showCTAButtons="true"
        style="pointer-events: auto"
      >
        <template #header>
          <div class="wishlist-title-wrapper">
            <div class="wishlist-title" @click="toggleWishlist">
              <i class="fa-solid fa-angle-left"></i>
              <h3>My Cart</h3>
            </div>
            <div
              class="clear-cart"
              v-if="updatedCartList.length > 0"
              @click="deleteFromCart(null, true)"
            >
              Clear Cart
            </div>
          </div>
        </template>
        <template #body>
          <div class="validity" v-if="cartValidity">
            <div class="validity-title1">
              Your cart expires on {{ cartValidityData }}.
            </div>
            <div class="validity-title2">
              Place order to secure your selections
            </div>
          </div>
          <ul
            class="cart-items-wrapper"
            :style="{
              alignItems:
                updatedCartList.length === 0 ? 'center' : 'flex-start',
              justifyContent:
                updatedCartList.length === 0 ? 'center' : 'flex-start',
            }"
          >
            <li
              class="cart-items"
              v-for="item in updatedCartList"
              :key="item.id"
            >
              <div class="cart-item-data">
                <div class="info-wrapper-nav">
                  {{ item?.order?.subjectName }}
                  <div class="report-type-nav">
                    Report Type:
                    {{
                      item?.order?.reportType === "FRESH"
                        ? "Fresh Report"
                        : `Report as at ${item?.order?.reportDetails?.report_dated} (${item?.order?.reportDetails?.report_label})`
                    }}
                  </div>
                </div>
                <i
                  class="fa-solid fa-xmark"
                  @click="deleteFromCart(item, false)"
                ></i>
              </div>
            </li>
            <li v-if="updatedCartList.length === 0">
              <div class="cart-item-data">Your cart is empty</div>
            </li>
          </ul>
        </template>
      </Drawer>
      <div
        v-if="showDrawer"
        class="bg-b-lue-mobile"
        v-on:click.self="toggleDrawer"
      >
        <transition name="slide">
          <div class="drawer-navigation-loggedin">
            <div class="navbar-optins" style="height: auto">
              <router-link
                to="/bankuserdashboard"
                v-on:click.self="showDrawer = false"
                :class="[
                  'navbar-option',
                  { 'active-link': isActiveRoute('/bankuserdashboard') },
                ]"
                style="display: flex"
                v-if="authorisedUserType"
                @click="
                  navBarCkicked();
                  toggleDrawer();
                "
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1245_35514)">
                    <path
                      d="M4 13H10C10.55 13 11 12.55 11 12V4C11 3.45 10.55 3 10 3H4C3.45 3 3 3.45 3 4V12C3 12.55 3.45 13 4 13ZM4 21H10C10.55 21 11 20.55 11 20V16C11 15.45 10.55 15 10 15H4C3.45 15 3 15.45 3 16V20C3 20.55 3.45 21 4 21ZM14 21H20C20.55 21 21 20.55 21 20V12C21 11.45 20.55 11 20 11H14C13.45 11 13 11.45 13 12V20C13 20.55 13.45 21 14 21ZM13 4V8C13 8.55 13.45 9 14 9H20C20.55 9 21 8.55 21 8V4C21 3.45 20.55 3 20 3H14C13.45 3 13 3.45 13 4Z"
                      :fill="
                        isActiveRoute('/bankuserdashboard')
                          ? 'white'
                          : '#11365A'
                      "
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1245_35514">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div
                  class="option-lable"
                  :style="{
                    color: isActiveRoute('/bankuserdashboard')
                      ? 'white'
                      : '#11365A',
                  }"
                >
                  Dashboard
                </div>
              </router-link>
              <router-link
                to="/search"
                :class="[
                  'navbar-option',
                  { 'active-link': isActiveRoute('/search') },
                ]"
                @click="
                  navBarCkicked();
                  toggleDrawer();
                "
                style="display: flex"
              >
                <img
                  src="@/assets/Icons/search.webp"
                  alt="Credence Search"
                  class="icon-image"
                />
                <div
                  class="option-lable"
                  :style="{
                    color: isActiveRoute('/search') ? 'white' : '#11365A',
                  }"
                >
                  Search
                </div>
              </router-link>
              <router-link
                to="/MyOrders?tab=active-orders"
                v-if="!isRM && !isAdmin"
                :class="[
                  'navbar-option',
                  { 'active-link': isActiveRoute('/MyOrders') },
                ]"
                @click="
                  navBarCkicked();
                  toggleDrawer();
                "
                style="display: flex"
              >
                <img
                  src="@/assets/Icons/orders.webp"
                  alt="Credence My Orders"
                  class="icon-image"
                />
                <div
                  class="option-lable"
                  :style="{
                    color: isActiveRoute('/MyOrders') ? 'white' : '#11365A',
                  }"
                >
                  Orders
                </div>
              </router-link>
              <router-link
                to="/profile?tab=teams"
                v-on:click.self="showDrawer = false"
                :class="[
                  'navbar-option',
                  {
                    'active-link': isActiveRoute('/profile', { tab: 'teams' }),
                  },
                ]"
                style="display: flex"
                @click="
                  navBarCkicked();
                  toggleDrawer();
                "
                v-if="userType"
              >
                <img
                  src="@/assets/Icons/team.webp"
                  alt="Credence My Teams"
                  class="icon-image"
                />
                <div
                  class="option-lable"
                  :style="{
                    color: isActiveRoute('/profile', { tab: 'teams' })
                      ? 'white'
                      : '#11365A',
                  }"
                >
                  Manage Team
                </div>
              </router-link>
              <router-link
                to="/banks-&-hq"
                v-if="isRM"
                v-on:click.self="showDrawer = false"
                :class="[
                  'navbar-option',
                  { 'active-link': isActiveRoute('/banks-&-hq') },
                ]"
                style="display: flex"
                @click="
                  navBarCkicked();
                  toggleDrawer();
                "
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1245_35331)">
                    <path
                      d="M2.25 9.75002H4.5V15.75H3C2.80109 15.75 2.61032 15.829 2.46967 15.9697C2.32902 16.1103 2.25 16.3011 2.25 16.5C2.25 16.6989 2.32902 16.8897 2.46967 17.0304C2.61032 17.171 2.80109 17.25 3 17.25H21C21.1989 17.25 21.3897 17.171 21.5303 17.0304C21.671 16.8897 21.75 16.6989 21.75 16.5C21.75 16.3011 21.671 16.1103 21.5303 15.9697C21.3897 15.829 21.1989 15.75 21 15.75H19.5V9.75002H21.75C21.9132 9.74986 22.0719 9.69648 22.202 9.59797C22.3321 9.49947 22.4265 9.36122 22.4709 9.2042C22.5153 9.04717 22.5073 8.87995 22.4481 8.72788C22.3889 8.57582 22.2817 8.44722 22.1428 8.36159L12.3928 2.36158C12.2747 2.28894 12.1387 2.25049 12 2.25049C11.8613 2.25049 11.7253 2.28894 11.6072 2.36158L1.85719 8.36159C1.71828 8.44722 1.61108 8.57582 1.55187 8.72788C1.49266 8.87995 1.48466 9.04717 1.52908 9.2042C1.57351 9.36122 1.66793 9.49947 1.79803 9.59797C1.92814 9.69648 2.08681 9.74986 2.25 9.75002ZM6 9.75002H9V15.75H6V9.75002ZM13.5 9.75002V15.75H10.5V9.75002H13.5ZM18 15.75H15V9.75002H18V15.75ZM12 3.88033L19.1006 8.25002H4.89937L12 3.88033ZM23.25 19.5C23.25 19.6989 23.171 19.8897 23.0303 20.0304C22.8897 20.171 22.6989 20.25 22.5 20.25H1.5C1.30109 20.25 1.11032 20.171 0.96967 20.0304C0.829018 19.8897 0.75 19.6989 0.75 19.5C0.75 19.3011 0.829018 19.1103 0.96967 18.9697C1.11032 18.829 1.30109 18.75 1.5 18.75H22.5C22.6989 18.75 22.8897 18.829 23.0303 18.9697C23.171 19.1103 23.25 19.3011 23.25 19.5Z"
                      :fill="isActiveRoute('/banks-&-hq') ? 'white' : '#11365A'"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1245_35331">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div
                  class="option-lable"
                  :style="{
                    color: isActiveRoute('/banks-&-hq') ? 'white' : '#11365A',
                  }"
                >
                  Banks & HQ
                </div>
              </router-link>
              <router-link
                to="/contact"
                v-if="!isRM && !isAdmin"
                :class="[
                  'navbar-option',
                  { 'active-link': isActiveRoute('/contact') },
                ]"
                @click="
                  navBarCkicked();
                  toggleDrawer();
                "
                style="display: flex"
              >
                <img
                  src="@/assets/Icons/helpdesk.webp"
                  alt="Credence Helpdesk"
                  class="icon-image"
                />
                <div
                  class="option-lable"
                  :style="{
                    color: isActiveRoute('/contact') ? 'white' : '#11365A',
                  }"
                >
                  Helpdesk
                </div>
              </router-link>
              <router-link
                to="/sample-reports"
                v-if="userTokenAvailable && !isAdmin"
                :class="[
                  'navbar-option',
                  { 'active-link': isActiveRoute('/sample-reports') },
                ]"
                @click="
                  navBarCkicked();
                  toggleDrawer();
                "
                :style="{
                  display: 'flex',
                  animation: showBlinking
                    ? 'blinking 1s linear infinite'
                    : 'none',
                }"
              >
                <img
                  src="@/assets/Icons/sample.webp"
                  alt="Credence My Orders"
                  class="icon-image"
                />
                <div
                  class="option-lable"
                  :style="{
                    color: isActiveRoute('/sample-reports')
                      ? 'white'
                      : '#11365A',
                  }"
                >
                  Sample Reports
                </div>
              </router-link>
              <router-link
                to="/blog-editor"
                v-if="isAdmin"
                :class="[
                  'navbar-option',
                  { 'active-link': isActiveRoute('/blog-editor') },
                ]"
                @click="
                  navBarCkicked();
                  toggleDrawer();
                "
                style="display: flex"
              >
                <img
                  src="@/assets/Icons/blog.webp"
                  alt="Credence My Orders"
                  class="icon-image"
                />
                <div
                  class="option-lable"
                  :style="{
                    color: isActiveRoute('/blog-editor') ? 'white' : '#11365A',
                  }"
                >
                  Post a Blog
                </div>
              </router-link>
              <!-- <router-link
                to="/rewards"
                v-if="isRetail"
                :class="[
                  'navbar-option',
                  { 'active-link': isActiveRoute('/rewards') },
                ]"
                style="display: flex"
                @click="
                  navBarCkicked();
                  toggleDrawer();
                "
              >
                <img
                  src="@/assets/Icons/reward1.webp"
                  alt="Credence My Orders"
                  class="icon-image"
                />
                <div
                  class="option-lable"
                  :style="{
                    color: isActiveRoute('/rewards') ? 'white' : '#11365A',
                  }"
                >
                  Rewards
                </div>
              </router-link> -->
            </div>
            <div
              class="profile-button"
              @click="profileClicked"
              v-if="userName !== ''"
              ref="profile"
            >
              <div class="user-profile">
                <svg
                  width="45"
                  height="45"
                  viewBox="0 0 45 45"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M22.5 41.2688C32.8553 41.2688 41.25 32.8741 41.25 22.5188C41.25 12.1635 32.8553 3.7688 22.5 3.7688C12.1447 3.7688 3.75 12.1635 3.75 22.5188C3.75 32.8741 12.1447 41.2688 22.5 41.2688Z"
                    fill="#11365A"
                  />
                  <path
                    d="M22.5 13.0125C18.6188 13.0125 15.4688 16.1625 15.4688 20.0437C15.4688 23.85 18.45 26.9437 22.4062 27.0562C22.4625 27.0562 22.5375 27.0562 22.575 27.0562C22.6125 27.0562 22.6687 27.0562 22.7062 27.0562C22.725 27.0562 22.7438 27.0562 22.7438 27.0562C26.5313 26.925 29.5125 23.85 29.5312 20.0437C29.5312 16.1625 26.3812 13.0125 22.5 13.0125Z"
                    fill="#11365A"
                  />
                  <path
                    d="M35.2126 36.3C31.8751 39.375 27.4126 41.2687 22.5001 41.2687C17.5876 41.2687 13.1251 39.375 9.7876 36.3C10.2376 34.5937 11.4563 33.0375 13.2376 31.8375C18.3563 28.425 26.6813 28.425 31.7626 31.8375C33.5626 33.0375 34.7626 34.5937 35.2126 36.3Z"
                    fill="#11365A"
                  />
                </svg>
                <div class="user-name">{{ truncatedUserName }}</div>
              </div>
              <svg
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 6.5L6 1.5L11 6.5"
                  stroke="#11365A"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
              <div
                class="profile-menu"
                v-if="profileMenuActive"
                ref="profileMenu"
              >
                <div
                  class="profile-menu-item"
                  @click="
                    goToProfile();
                    toggleDrawer();
                    navBarCkicked();
                  "
                >
                  User Profile
                </div>
                <div
                  class="profile-menu-item"
                  @click="
                    processLogout();
                    toggleDrawer();
                    navBarCkicked();
                  "
                >
                  Log Out
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
  <div
    style="
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      position: fixed;
      width: 100%;
      z-index: 1;
      pointer-events: none;
    "
    v-if="userTokenAvailable && !showMobileMenu"
  >
    <div class="dashboard-navigation-container">
      <div class="dasboard-nav-menu">
        <router-link
          :to="userType ? '/search' : '/search'"
          class="router-link-no-hover"
          @click="navBarCkicked()"
        >
          <div class="frame-1">
            <img class="image-2" src="@/assets/logo.webp" alt="Credence" />
          </div>
        </router-link>
        <div class="line-16"></div>
        <div class="navbar-optins-wrapper">
          <div class="navbar-optins">
            <router-link
              to="/bankuserdashboard"
              v-on:click.self="showDrawer = false"
              :class="[
                'navbar-option',
                { 'active-link': isActiveRoute('/bankuserdashboard') },
              ]"
              @click="navBarCkicked()"
              v-if="authorisedUserType"
            >
              <img
                src="@/assets/Icons/dashboard.webp"
                alt="Credence User Dashboard"
                class="icon-image"
              />
              <div
                class="option-lable"
                :style="{
                  color: isActiveRoute('/bankuserdashboard')
                    ? 'white'
                    : '#11365A',
                }"
              >
                Dashboard
              </div>
            </router-link>
            <router-link
              to="/search"
              :class="[
                'navbar-option',
                { 'active-link': isActiveRoute('/search') },
              ]"
              @click="navBarCkicked()"
            >
              <img
                src="@/assets/Icons/search.webp"
                alt="Credence Search"
                class="icon-image"
              />
              <div
                class="option-lable"
                :style="{
                  color: isActiveRoute('/search') ? 'white' : '#11365A',
                }"
              >
                Search
              </div>
            </router-link>
            <router-link
              to="/banks-&-hq"
              v-if="isRM"
              :class="[
                'navbar-option',
                { 'active-link': isActiveRoute('/banks-&-hq') },
              ]"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.25 9.75002H4.5V15.75H3C2.80109 15.75 2.61032 15.829 2.46967 15.9697C2.32902 16.1103 2.25 16.3011 2.25 16.5C2.25 16.6989 2.32902 16.8897 2.46967 17.0304C2.61032 17.171 2.80109 17.25 3 17.25H21C21.1989 17.25 21.3897 17.171 21.5303 17.0304C21.671 16.8897 21.75 16.6989 21.75 16.5C21.75 16.3011 21.671 16.1103 21.5303 15.9697C21.3897 15.829 21.1989 15.75 21 15.75H19.5V9.75002H21.75C21.9132 9.74986 22.0719 9.69648 22.202 9.59797C22.3321 9.49947 22.4265 9.36122 22.4709 9.2042C22.5153 9.04717 22.5073 8.87995 22.4481 8.72788C22.3889 8.57582 22.2817 8.44722 22.1428 8.36159L12.3928 2.36158C12.2747 2.28894 12.1387 2.25049 12 2.25049C11.8613 2.25049 11.7253 2.28894 11.6072 2.36158L1.85719 8.36159C1.71828 8.44722 1.61108 8.57582 1.55187 8.72788C1.49266 8.87995 1.48466 9.04717 1.52908 9.2042C1.57351 9.36122 1.66793 9.49947 1.79803 9.59797C1.92814 9.69648 2.08681 9.74986 2.25 9.75002ZM6 9.75002H9V15.75H6V9.75002ZM13.5 9.75002V15.75H10.5V9.75002H13.5ZM18 15.75H15V9.75002H18V15.75ZM12 3.88033L19.1006 8.25002H4.89937L12 3.88033ZM23.25 19.5C23.25 19.6989 23.171 19.8897 23.0303 20.0304C22.8897 20.171 22.6989 20.25 22.5 20.25H1.5C1.30109 20.25 1.11032 20.171 0.96967 20.0304C0.829018 19.8897 0.75 19.6989 0.75 19.5C0.75 19.3011 0.829018 19.1103 0.96967 18.9697C1.11032 18.829 1.30109 18.75 1.5 18.75H22.5C22.6989 18.75 22.8897 18.829 23.0303 18.9697C23.171 19.1103 23.25 19.3011 23.25 19.5Z"
                  :fill="isActiveRoute('/banks-&-hq') ? 'white' : '#11365A'"
                />
              </svg>

              <div
                class="option-lable"
                :style="{
                  color: isActiveRoute('/banks-&-hq') ? 'white' : '#11365A',
                }"
              >
                Banks & HQ
              </div>
            </router-link>
            <router-link
              to="/MyOrders?tab=active-orders"
              :class="[
                'navbar-option',
                { 'active-link': isActiveRoute('/MyOrders') },
              ]"
              @click="navBarCkicked()"
              v-if="!isRM && !isAdmin"
            >
              <img
                src="@/assets/Icons/orders.webp"
                alt="Credence My Orders"
                class="icon-image"
              />
              <div
                class="option-lable"
                :style="{
                  color: isActiveRoute('/MyOrders') ? 'white' : '#11365A',
                }"
              >
                Orders
              </div>
            </router-link>
            <router-link
              to="/profile?tab=teams"
              :class="[
                'navbar-option',
                { 'active-link': isActiveRoute('/profile', { tab: 'teams' }) },
              ]"
              @click="navBarCkicked()"
              v-if="userType"
            >
              <img
                src="@/assets/Icons/team.webp"
                alt="Credence My Teams"
                class="icon-image"
              />
              <div
                class="option-lable"
                :style="{
                  color: isActiveRoute('/profile', { tab: 'teams' })
                    ? 'white'
                    : '#11365A',
                }"
              >
                Manage Team
              </div>
            </router-link>
            <router-link
              to="/contact"
              v-if="!isRM && !isAdmin"
              :class="[
                'navbar-option',
                { 'active-link': isActiveRoute('/contact') },
              ]"
              @click="navBarCkicked()"
            >
              <img
                src="@/assets/Icons/helpdesk.webp"
                alt="Credence Helpdesk"
                class="icon-image"
              />
              <div
                class="option-lable"
                :style="{
                  color: isActiveRoute('/contact') ? 'white' : '#11365A',
                }"
              >
                Helpdesk
              </div>
            </router-link>
            <router-link
              to="/sample-reports"
              v-if="userTokenAvailable && !isAdmin"
              :class="[
                'navbar-option',
                { 'active-link': isActiveRoute('/sample-reports') },
              ]"
              @click="navBarCkicked()"
              :style="{
                // boxShadow: showBoxShadow ? '0px 0px 24px 0px red' : 'none',
                animation: showBlinking
                  ? 'blinking 1s linear infinite'
                  : 'none',
              }"
            >
              <img
                src="@/assets/Icons/sample.webp"
                alt="Credence My Orders"
                class="icon-image"
              />
              <div
                class="option-lable"
                :style="{
                  color: isActiveRoute('/sample-reports') ? 'white' : '#11365A',
                }"
              >
                Sample Reports
              </div>
            </router-link>
            <router-link
              to="/blog-editor"
              v-if="isAdmin"
              :class="[
                'navbar-option',
                { 'active-link': isActiveRoute('/blog-editor') },
              ]"
              @click="navBarCkicked()"
            >
              <img
                src="@/assets/Icons/blog.webp"
                alt="Credence My Orders"
                class="icon-image"
              />
              <div
                class="option-lable"
                :style="{
                  color: isActiveRoute('/blog-editor') ? 'white' : '#11365A',
                }"
              >
                Post a Blog
              </div>
            </router-link>
            <!-- <router-link
              to="/rewards"
              v-if="isRetail"
              :class="[
                'navbar-option',
                { 'active-link': isActiveRoute('/rewards') },
              ]"
              @click="navBarCkicked()"
            >
              <img
                src="@/assets/Icons/reward1.webp"
                alt="Credence My Orders"
                class="icon-image"
              />
              <div
                class="option-lable"
                :style="{
                  color: isActiveRoute('/rewards') ? 'white' : '#11365A',
                }"
              >
                Rewards
              </div>
            </router-link> -->
          </div>
          <div
            class="profile-button"
            @click="profileClicked"
            v-if="userName !== ''"
            ref="profile"
          >
            <div class="user-profile">
              <svg
                width="45"
                height="45"
                viewBox="0 0 45 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M22.5 41.2688C32.8553 41.2688 41.25 32.8741 41.25 22.5188C41.25 12.1635 32.8553 3.7688 22.5 3.7688C12.1447 3.7688 3.75 12.1635 3.75 22.5188C3.75 32.8741 12.1447 41.2688 22.5 41.2688Z"
                  fill="#11365A"
                />
                <path
                  d="M22.5 13.0125C18.6188 13.0125 15.4688 16.1625 15.4688 20.0437C15.4688 23.85 18.45 26.9437 22.4062 27.0562C22.4625 27.0562 22.5375 27.0562 22.575 27.0562C22.6125 27.0562 22.6687 27.0562 22.7062 27.0562C22.725 27.0562 22.7438 27.0562 22.7438 27.0562C26.5313 26.925 29.5125 23.85 29.5312 20.0437C29.5312 16.1625 26.3812 13.0125 22.5 13.0125Z"
                  fill="#11365A"
                />
                <path
                  d="M35.2126 36.3C31.8751 39.375 27.4126 41.2687 22.5001 41.2687C17.5876 41.2687 13.1251 39.375 9.7876 36.3C10.2376 34.5937 11.4563 33.0375 13.2376 31.8375C18.3563 28.425 26.6813 28.425 31.7626 31.8375C33.5626 33.0375 34.7626 34.5937 35.2126 36.3Z"
                  fill="#11365A"
                />
              </svg>
              <div class="user-name">{{ truncatedUserName }}</div>
              <div
                class="profile-menu"
                v-if="profileMenuActive"
                ref="profileMenu"
              >
                <div
                  class="profile-menu-item"
                  @click="
                    goToProfile();
                    navBarCkicked();
                  "
                >
                  User Profile
                </div>
                <div
                  class="profile-menu-item"
                  @click="
                    processLogout();
                    navBarCkicked();
                  "
                >
                  Log Out
                </div>
              </div>
            </div>
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 6.5L6 1.5L11 6.5"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 55px;
        padding: 0 4vw;
        background: #e2eaf5;
        pointer-events: auto;
      "
    >
      <div class="navbar-title">{{ currentScreenName }}</div>
      <div
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 20px;
        "
      >
        <router-link
          to="/rewards"
          v-if="userTokenAvailable && isRetail && !showMobileMenu"
        >
          <img
            src="@/assets/Icons/reward1.webp"
            alt="Credence My Orders"
            class="icon-image"
          />
        </router-link>
        <!-- <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="20"
          viewBox="0 0 18 20"
          fill="none"
          style="cursor: pointer"
        >
          <path
            d="M17.7938 14.4944C17.2735 13.5981 16.5 11.0622 16.5 7.75C16.5 5.76088 15.7098 3.85322 14.3033 2.4467C12.8968 1.04018 10.9891 0.25 9.00001 0.25C7.01089 0.25 5.10323 1.04018 3.69671 2.4467C2.29019 3.85322 1.50001 5.76088 1.50001 7.75C1.50001 11.0631 0.725637 13.5981 0.205324 14.4944C0.0724531 14.7222 0.00201297 14.9811 0.00110747 15.2449C0.000201977 15.5086 0.0688633 15.768 0.200167 15.9967C0.331471 16.2255 0.520774 16.4156 0.748986 16.5478C0.977197 16.6801 1.23625 16.7498 1.50001 16.75H5.32595C5.49899 17.5967 5.95916 18.3577 6.62864 18.9042C7.29811 19.4507 8.1358 19.7492 9.00001 19.7492C9.86422 19.7492 10.7019 19.4507 11.3714 18.9042C12.0409 18.3577 12.501 17.5967 12.6741 16.75H16.5C16.7637 16.7496 17.0226 16.6798 17.2507 16.5475C17.4788 16.4151 17.668 16.225 17.7992 15.9963C17.9303 15.7676 17.9989 15.5083 17.998 15.2446C17.997 14.9809 17.9266 14.7222 17.7938 14.4944ZM9.00001 18.25C8.53484 18.2499 8.08115 18.1055 7.70139 17.8369C7.32162 17.5683 7.03444 17.1886 6.87939 16.75H11.1206C10.9656 17.1886 10.6784 17.5683 10.2986 17.8369C9.91887 18.1055 9.46518 18.2499 9.00001 18.25ZM1.50001 15.25C2.22189 14.0087 3.00001 11.1325 3.00001 7.75C3.00001 6.1587 3.63215 4.63258 4.75737 3.50736C5.88259 2.38214 7.40871 1.75 9.00001 1.75C10.5913 1.75 12.1174 2.38214 13.2427 3.50736C14.3679 4.63258 15 6.1587 15 7.75C15 11.1297 15.7763 14.0059 16.5 15.25H1.50001Z"
            fill="black"
          />
        </svg> -->
        <div class="cart-icon-wrapper" v-if="!isRM && !isAdmin">
          <Dropdown
            :triggers="[]"
            :shown="isCheckoutOpen"
            :autoHide="true"
            style="display: flex"
          >
            <img
              src="@/assets/Icons/cart.webp"
              alt="Credence My Orders"
              class="icon-image"
              style="cursor: pointer"
              @click="toggleWishlist"
            />
            <div class="cart-count" v-if="cartCount > 0">{{ cartCount }}</div>
            <template #popper>
              <div class="tooltip-content">
                <CartAnimation />
                <div class="tooltip-message">Proceed to checkout</div>
              </div>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <Drawer
      :open="drawerOpen"
      @close="toggleWishlist"
      @placeorder="toggleCheckout"
      :cartCount="updatedCartList.length"
      :showCTAButtons="true"
      style="pointer-events: auto"
    >
      <template #header>
        <div class="wishlist-title-wrapper">
          <div class="wishlist-title" @click="toggleWishlist">
            <i class="fa-solid fa-angle-left"></i>
            <h3>My Cart</h3>
          </div>
          <div
            class="clear-cart"
            v-if="updatedCartList.length > 0"
            @click="deleteFromCart(null, true)"
          >
            Clear Cart
          </div>
        </div>
      </template>
      <template #body>
        <div class="validity" v-if="cartValidity">
          <div class="validity-title1">
            Your cart expires on {{ cartValidityData }}.
          </div>
          <div class="validity-title2">
            Place order to secure your selections
          </div>
        </div>
        <ul
          class="cart-items-wrapper"
          :style="{
            alignItems: updatedCartList.length === 0 ? 'center' : 'flex-start',
            justifyContent:
              updatedCartList.length === 0 ? 'center' : 'flex-start',
          }"
        >
          <li class="cart-items" v-for="item in updatedCartList" :key="item.id">
            <div class="cart-item-data">
              <div class="info-wrapper-nav">
                {{ item?.order?.subjectName }}
                <div class="report-type-nav">
                  Report Type:
                  {{
                    item?.order?.reportType === "FRESH"
                      ? "Fresh Report"
                      : `Report as at ${item?.order?.reportDetails?.report_dated} (${item?.order?.reportDetails?.report_label})`
                  }}
                </div>
              </div>
              <i
                class="fa-solid fa-xmark"
                @click="deleteFromCart(item, false)"
              ></i>
            </div>
          </li>
          <li v-if="updatedCartList.length === 0">
            <div class="cart-item-data">Your cart is empty</div>
          </li>
        </ul>
      </template>
    </Drawer>
  </div>
  <LoaderPopup v-if="showLoaderPopup" />
  <session-expired-modal v-if="sessionExpired" @login="redirectToLogin" />
  <CartCheckout
    v-if="toggleCheckoutModal"
    @close="toggleCheckout"
    @placeorder="placeBulkOrder"
    :cartLoader="cartLoader"
  />
  <BulkOrderConfirmation
    v-if="bulkOrderConfirmation"
    @close="toggleBulkOrderConfirmation"
  />
  <InviteNewUser v-if="showInviteNewUser" @close="toggleInviteNewUser()" />
  <Toastify ref="toastContainer" />
  <router-view />
</template>
<script>
// import { ref } from "vue";
import VueCookies from "vue-cookies";
import LoaderPopup from "@/components/LoaderPopup.vue";
import ButtonProperty1SecondaryHoverFalse from "./components/HomePage/ButtonProperty1SecondaryHoverFalse/ButtonProperty1SecondaryHoverFalse.vue";
import ButtonProperty1PrimaryHoverFalse from "./components/HomePage/ButtonProperty1PrimaryHoverFalse/ButtonProperty1PrimaryHoverFalse.vue";
import { mapGetters, mapActions } from "vuex";
import SessionExpiredModal from "@/views/Modals/SessionExpiredModal.vue";
import InviteNewUser from "@/views/Modals/InviteNewUser.vue";
import {
  Logout,
  GetCartItems,
  DeleteFromCart,
  CheckoutCart,
  GetMyReferrals,
} from "./api/APIs";
import Drawer from "@/components/Drawer.vue";
import CartCheckout from "@/views/Modals/CartCheckout.vue";
import Toastify from "@/components/Toastify.vue";
import BulkOrderConfirmation from "@/views/Modals/BulkOrderConfirmation.vue";
import { Dropdown } from "floating-vue";
import "floating-vue/dist/style.css";
import CartAnimation from "@/assets/Animation/CartCheckout/CartCheckout.vue";
import shareLinkIcon from "@/assets/SocialMediaIcons/link.webp";
import whatsappIcon from "@/assets/SocialMediaIcons/whatsapp.webp";
import linkedinIcon from "@/assets/SocialMediaIcons/linkedin.webp";
// import facebookIcon from "@/assets/SocialMediaIcons/facebook.webp";
import twitterIcon from "@/assets/SocialMediaIcons/x.webp";
import config from "@/config.js";

export default {
  name: "App",
  components: {
    LoaderPopup,
    ButtonProperty1SecondaryHoverFalse,
    ButtonProperty1PrimaryHoverFalse,
    SessionExpiredModal,
    Drawer,
    CartCheckout,
    BulkOrderConfirmation,
    Toastify,
    Dropdown,
    CartAnimation,
    InviteNewUser,
  },
  data() {
    return {
      guestUser: true,
      userName: "",
      showLoaderPopup: false,
      showLoginPopup: false,
      showLogoutPopup: false,
      showHistoryPopup: false,
      showOrdersPopup: false,
      showOrderDetailsPopup: false,
      orderDetailsModel: null,
      manualOrderForm: false,
      availableCredits: 0,
      userToken: null,
      showDrawer: false,
      showMobileMenu: window.innerWidth < 1023,
      mobile: window.innerWidth <= 767,
      clickOutsideDrawer: false,
      loginType: "",
      isProduction: process.env.NODE_ENV === "production",
      enableLogin: process.env.VUE_APP_ENABLE_LOGIN === "true",
      profileMenuActive: false,
      carouselSettings: [
        {
          dots: false,
          infinite: true,
          arrows: false,
          speed: 10000,
          slidesToShow: 5,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 0,
          variableWidth: true,
          cssEase: "linear",
          rtl: false,
          pauseOnHover: false,
        },
        {
          dots: false,
          infinite: true,
          arrows: false,
          speed: 10000,
          slidesToShow: 5,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 0,
          variableWidth: true,
          cssEase: "linear",
          rtl: true,
          pauseOnHover: false,
        },
      ],
      cartItemsList: [],
      toggleCheckoutModal: false,
      cartLoader: false,
      bulkOrderConfirmation: false,
      isCheckoutOpen: false,
      tooltipTimeout: null,
      showBoxShadow: false,
      showBlinking: false,
      myReferralList: [],
      showInviteNewUser: false,
      isTooltipOpen: false,
      shareOptions: [
        { type: "link", label: "Share Link", icon: shareLinkIcon },
        { type: "whatsapp", label: "Whatsapp", icon: whatsappIcon },
        { type: "linkedin", label: "LinkedIn", icon: linkedinIcon },
        // { type: "facebook", label: "Facebook", icon: facebookIcon },
        { type: "twitter", label: "Twitter", icon: twitterIcon },
      ],
      referralCredits: config.referralCredits,
      drawerOpen: false,
      rewardsDrawerOpen: false,
      cartValidity: false,
      cartValidityData: null,
    };
  },
  computed: {
    ...mapGetters(["cartCount", "sessionExpired"]),
    referralCode() {
      return this.$store.getters.referralCode;
    },
    credits() {
      return this.$store.getters.credits;
    },
    updatedCartList() {
      return this.cartItemsList;
    },
    updatedInvites() {
      return this.myReferralList;
    },
    currentScreenName() {
      const routeName = this.$route.name || "";

      return routeName
        .replace(/-/g, " ")
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/^./, (str) => str.toUpperCase());
    },
    authorisedUserType() {
      return ["HEAD", "MANAGER"].includes(VueCookies.get("user-type"));
    },
    userType() {
      return ["HEAD", "MANAGER", "EXECUTIVE"].includes(
        VueCookies.get("user-type")
      );
    },
    isRetail() {
      return VueCookies.get("user-type") === "RETAIL";
    },
    isAdmin() {
      return VueCookies.get("user-type") === "BLOG-ADMIN";
    },
    isRM() {
      return VueCookies.get("user-type") === "RM";
    },
    userTokenAvailable() {
      return this.userToken !== null;
    },
    appClass() {
      return this.userTokenAvailable ? "#app.logged-in" : "#app.not-logged-in";
    },
    isActiveRoute() {
      return (path, query = {}) => {
        const isSamePath = this.$route.path === path;
        const isSameQuery = Object.keys(query).every(
          (key) => this.$route.query[key] === query[key]
        );
        const hasNoQuery = Object.keys(query).length === 0;
        return isSamePath && (isSameQuery || hasNoQuery);
      };
    },
    truncatedUserName() {
      const user = VueCookies.get("user");
      if (user) {
        return user;
      } else {
        return "";
      }
    },
  },
  beforeMount() {
    this.userName = VueCookies.get("user");
    this.userToken = VueCookies.get("token");
  },
  watch: {
    $route(to) {
      if (to.path !== "/login") {
        this.handleMountedLogic();
      }
      if (
        VueCookies.get("token") &&
        VueCookies.get("order-count") == 0 &&
        localStorage.getItem("effectTriggered") === "false"
      ) {
        this.triggerBoxShadow();
        localStorage.setItem("effectTriggered", true);
      }
    },
  },
  mounted() {
    if (this.$route.path !== "/login") {
      this.handleMountedLogic();
    }
    window.addEventListener("resize", this.handleResize);
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
    document.removeEventListener("click", this.handleClickOutside);
  },
  updated() {
    window.scrollTo(0, 0);
  },
  methods: {
    ...mapActions(["updateCartCount"]),
    navBarCkicked() {
      this.drawerOpen = false;
      this.rewardsDrawerOpen = false;
    },
    toggleTooltip() {
      this.isTooltipOpen = !this.isTooltipOpen;
    },
    handleShare(type) {
      this.toggleTooltip();
      const baseUrl = window.location.origin;
      const referralMessage = `I’ve been using Credence (www.credencedata.com) for some really helpful business reports, especially for International business transactions. They provide great insights on International buyers and suppliers that are worth checking out.

Plus, if you sign up with my referral code ${this.referralCode}, you’ll get ${this.referralCredits} off your first purchase. :)

Here’s the link to sign up: ${baseUrl}/login?type=SignUp&refCode=${this.referralCode}`;
      const referralLink = `${baseUrl}/login?type=SignUp&refCode=${this.referralCode}`;
      if (type === "link") {
        this.copyToClipboard(true);
      } else {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        let shareUrl;
        switch (type) {
          case "whatsapp":
            shareUrl = `https://wa.me/?text=${encodeURIComponent(
              referralMessage
            )}`;
            break;
          case "linkedin":
            shareUrl = `https://www.linkedin.com/sharing/share-offsite/?text=${encodeURIComponent(
              referralMessage
            )}`;
            break;
          // case "facebook":
          //   shareUrl = `https://www.facebook.com/dialog/feed?display=popup&description=${encodeURIComponent(
          //     referralMessage
          //   )}`;
          //   break;
          case "twitter":
            shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
              referralMessage
            )}`;
            break;
        }

        if (isMobile) {
          const appUrl = this.getAppUrl(type, referralMessage, referralLink);
          window.location.href = appUrl;
          setTimeout(() => {
            window.location.href = shareUrl;
          }, 1000);
        } else {
          window.open(shareUrl, "_blank");
        }
      }
    },
    getAppUrl(type, referralMessage) {
      switch (type) {
        case "whatsapp":
          return `whatsapp://send?text=${encodeURIComponent(referralMessage)}`;
        case "linkedin":
          return `linkedin://shareArticle?mini=true&text=${encodeURIComponent(
            referralMessage
          )}`;
        // case "facebook":
        //   return `fb://faceweb/f?href=${encodeURIComponent(referralLink)}`;
        case "twitter":
          return `twitter://post?message=${encodeURIComponent(
            referralMessage
          )}`;
        default:
          return "";
      }
    },
    toggleInviteNewUser() {
      this.showInviteNewUser = !this.showInviteNewUser;
    },
    copyToClipboard(message) {
      const baseUrl = window.location.origin;
      const referralMessage = `I’ve been using Credence (www.credencedata.com) for some really helpful business reports, especially for International business transactions. They provide great insights on International buyers and suppliers that are worth checking out.

Plus, if you sign up with my referral code ${this.referralCode}, you’ll get ${this.referralCredits} off your first purchase. 

Here’s the link to sign up: ${baseUrl}/login?type=SignUp&refCode=${this.referralCode}`;
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard
          .writeText(message ? referralMessage : this.referralCode)
          .then(() => {
            alert(
              message
                ? "Message copied to clipboard! Paste it on any social media channel and share with your friends."
                : "Referral code copied to clipboard!"
            );
          })
          .catch((err) => {
            console.error("Failed to copy: ", err);
          });
      } else {
        const textarea = document.createElement("textarea");
        textarea.value = message ? referralMessage : this.referralCode;
        textarea.style.position = "fixed";
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        try {
          document.execCommand("copy");
          alert("Referral code copied to clipboard!");
        } catch (err) {
          console.error(
            message
              ? "Message copied to clipboard! Paste it on any social media channel and share with your friends."
              : "Fallback: Oops, unable to copy",
            err
          );
        }
        document.body.removeChild(textarea);
      }
    },
    triggerBoxShadow() {
      this.showBoxShadow = true;
      this.showBlinking = true;
      setTimeout(() => {
        this.showBoxShadow = false;
        this.showBlinking = false;
      }, 20000);
    },
    toggleCheckoutTooltip() {
      if (this.tooltipTimeout) {
        clearTimeout(this.tooltipTimeout);
      }

      this.isCheckoutOpen = !this.isCheckoutOpen;

      if (this.isCheckoutOpen) {
        this.tooltipTimeout = setTimeout(() => {
          this.isCheckoutOpen = false;
        }, 3000);
      }
    },
    async toggleWishlist() {
      try {
        await this.GetCartItems();
        if (this.mobile) {
          this.showDrawer = false;
        }
        this.drawerOpen = !this.drawerOpen;
      } catch (error) {
        console.error("Error fetching cart items:", error);
      }
    },
    async toggleRewards() {
      try {
        if (this.rewardsDrawerOpen === false) {
          await this.MyReferrals();
        }
        this.rewardsDrawerOpen = !this.rewardsDrawerOpen;
      } catch (error) {
        console.error("Error fetching rewards:", error);
      }
    },
    async toggleCheckout() {
      try {
        this.toggleCheckoutModal = !this.toggleCheckoutModal;
      } catch (error) {
        console.error("Error fetching cart items:", error);
      }
    },
    toggleBulkOrderConfirmation() {
      this.bulkOrderConfirmation = !this.bulkOrderConfirmation;
    },
    async GetCartItems() {
      try {
        const response = await GetCartItems();
        if (response.cart) {
          this.cartItemsList = response.cart;
          this.cartValidity = response.cartValidityExpiring
            ? response.cartValidityExpiring
            : false;
          this.cartValidityData = response.cartValidityDate
            ? response.cartValidityDate
            : null;
          this.updateCartCount(response?.size);
        } else {
          return this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    async MyReferrals() {
      try {
        const response = await GetMyReferrals();
        if (response) {
          this.myReferralList = response;
        } else {
          return this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    async placeBulkOrder(deliveryEmail, isDiscountApplied) {
      this.cartLoader = true;
      const payload =
        this.isRetail === true
          ? {
              email: deliveryEmail,
              isDiscountApplied: isDiscountApplied,
            }
          : {
              email: deliveryEmail,
            };
      try {
        const response = await CheckoutCart(payload);
        if (response.message === "SUCCESS") {
          if (this.isRetail === true) {
            this.cartLoader = false;
            this.GetCartItems();
            this.toggleCheckout();
            this.drawerOpen = false;
            window.location.href = response?.result?.paymentLink;
          } else {
            this.cartLoader = false;
            this.GetCartItems();
            this.toggleCheckout();
            this.drawerOpen = false;
            this.toggleBulkOrderConfirmation();
          }
        } else {
          return this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    async deleteFromCart(item, clearCart) {
      const payload = clearCart
        ? { clearCart: clearCart }
        : { itemId: item.id };
      try {
        const response = await DeleteFromCart(payload);
        if (response.message === "Deleted from cart") {
          this.GetCartItems();
        } else if (response.message === "Cart cleared") {
          this.GetCartItems();
        } else {
          return this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    redirectToLogin() {
      this.tokenExpired();
      this.$store.commit("setSessionExpired", false);
    },
    profileClicked() {
      this.profileMenuActive = !this.profileMenuActive;
    },
    handleClickOutside(event) {
      if (
        this.profileMenuActive &&
        this.$refs.profileMenu &&
        this.$refs.profile &&
        !this.$refs.profileMenu.contains(event.target) &&
        !this.$refs.profile.contains(event.target)
      ) {
        this.profileMenuActive = false;
      }
    },
    goToProfile() {
      this.profileMenuActive = false;
      this.$router.push("/profile?tab=myaccount");
    },
    redirectToAboutUs() {
      this.$router.push("/aboutus");
      this.showDrawer = false;
    },
    redirectToBlogs() {
      this.$router.push("/blogs");
    },
    redirectToProducts() {
      this.$router.push("/product");
      this.showDrawer = false;
    },
    redirectToHome() {
      if (this.$route.path === "/") {
        location.reload();
      } else {
        this.$router.push("/");
        this.showDrawer = false;
      }
    },
    handleMountedLogic() {
      this.userToken = VueCookies.get("token");
      this.userName = VueCookies.get("user");
    },
    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
    },
    handleResize() {
      this.showMobileMenu = window.innerWidth < 1023;
    },
    showLoader() {
      this.showLoaderPopup = true;
    },
    hideLoader() {
      this.showLoaderPopup = false;
    },
    showLogin(actionType) {
      this.loginType = actionType;
      this.$router.push({ path: "/login", query: { type: actionType } });
    },
    async processLogout() {
      const user = VueCookies.get("email");
      const password = VueCookies.get("password");
      try {
        const response = await Logout(user, password);
        if (response) {
          this.profileMenuActive = false;
          localStorage.setItem("effectTriggered", false);
          const cookiesToRemove = [
            "token",
            "user",
            "password",
            "email",
            "user-type",
            "id",
            "designation",
            "designationId",
            "phone",
            "order-count",
            "entityId",
            "branch",
            "RM",
            "phone_w_countryCode",
          ];

          cookiesToRemove.forEach((cookie) => {
            if (VueCookies.get(cookie)) {
              VueCookies.remove(cookie);
            }
          });
          this.userToken = null;
          this.userName = "";
          this.$store.dispatch("clearData");
          this.$router.push("/");
          // window.location.reload();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async tokenExpired() {
      const user = VueCookies.get("email");
      const password = VueCookies.get("password");
      try {
        const response = await Logout(user, password);
        if (response) {
          this.profileMenuActive = false;
          VueCookies.remove("token");
          VueCookies.remove("user");
          VueCookies.remove("password");
          VueCookies.remove("email");
          // VueCookies.remove("credits");
          VueCookies.remove("user-type");
          VueCookies.remove("order-count");
          VueCookies.remove("id");
          VueCookies.remove("designation");
          VueCookies.remove("designationId");
          VueCookies.remove("phone");
          this.userToken = null;
          this.userName = "";
          this.$router.push({ path: "/login", query: { type: "Login" } });
        }
      } catch (error) {
        console.error(error);
      }
    },
    getCDNlink(fileKey) {
      let baseUrl = "";
      if (
        process.env.NODE_ENV === "development" ||
        process.env.NODE_ENV === "staging"
      ) {
        baseUrl = config.stagingCDN;
      } else if (process.env.NODE_ENV === "production") {
        baseUrl = config.prodCDN;
      }

      return `${baseUrl}${fileKey}`;
    },
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
@keyframes blinking {
  0% {
    box-shadow: 0px 0px 24px 0px rgba(143, 200, 255, 0);
  }
  50% {
    box-shadow: 0px 0px 24px 0px rgba(143, 200, 255, 1);
  }
  100% {
    box-shadow: 0px 0px 24px 0px rgba(143, 200, 255, 0);
  }
}
#app {
  font-family: "Montserrat-Medium", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f2f8ff;
  min-height: 100vh;
  transition: margin-top 0.3s;
}
#app.logged-in {
  margin-top: 0;
}
.link {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600 !important;
}
#app.not-logged-in {
  margin-top: 60px;
}
.icon-image {
  width: 25px;
  height: 25px;
}
.navbar-title {
  color: var(--Secondary-Grey-900, #2b3674);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.48px;
}
.wrapper-class {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.referral-title-2 {
  color: var(--Secondary-Grey-900, #2b3674);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.refer {
  display: flex;
  padding: 5px 14px;
  justify-content: center;
  align-items: center;
  gap: 14px;
  border-radius: 13px;
  background: #11365a;
  color: #fcd600;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  cursor: pointer;
}
.social-media-icons {
  width: 20px;
}
.tooltip-content-profile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 8px;
  width: 140px;
  gap: 13px;
}
.tooltip-message-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  gap: 10px;
  cursor: pointer;
}
.available-credits {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid rgb(181, 181, 181);
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  padding: 5px 15px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  min-width: 85px;
}
.share-invitation-main {
  display: flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 13px;
  border: 2px solid #11365a;
  background: #fff;
  color: var(--Dark-Blue, #11365a);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.16px;
  min-width: 85px;
}
.invitationstatus-main {
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 33px;
}
.invitation-status-main {
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.14px;
  text-transform: capitalize;
}
.referred-email-main {
  color: var(--Dark-Blue, #11365a);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.32px;
  opacity: 0.9px;
}
.cart-items-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  height: 65%;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 0;
}
.cart-icon-wrapper {
  position: relative;
  display: inline-block;
}
.info-wrapper-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
}
.cart-item-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
}
.cart-count {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #11365a;
  color: white;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.2px;
  min-width: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  width: 18px;
  box-sizing: border-box;
}
.tooltip-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 180px;
  padding-top: 8px;
  /* gap: 15px; */
}
.tooltip-checkout {
  background-color: #11365a;
  border-radius: 10px;
  color: white;
  height: 40px;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 18px;
  font-weight: 500;
  align-self: stretch;
  cursor: pointer;
  outline: none;
  width: 100%;
  text-align: center;
}
.cart-items {
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.2px;
  align-self: stretch;
  padding: 12px;
  border-radius: 16px;
  border: 1px solid rgba(24, 195, 223, 0.2);
  background: #fff;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.08);
}
.report-type-nav {
  font-size: 14px;
  font-weight: 500;
  color: #3e4855;
  opacity: 0.5;
}
.wishlist-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.wishlist-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-family: "Montserrat-Medium", sans-serif;
  cursor: pointer;
}
.clear-cart {
  color: var(--Main-COlor, #26bbc2);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  cursor: pointer;
}
input[type="text"],
input[type="textarea"] {
  height: 30px;
  border-radius: 0.5em;
  box-shadow: 0 1px 2px #aaa;
  margin: 8px 0;
  padding: 0px 20px;
}
textarea {
  height: 100px;
  border-radius: 0.5em;
  box-shadow: 0 1px 2px #aaa;
  margin: 8px 0;
  padding: 7px 20px;
}
button {
  font-weight: bold;
  font-size: 15px;
  width: 100px;
  height: 35px;
  border-radius: 0.5em;
  color: #ffffff;
  background-color: #d43451;
}
#userMenu {
  overflow: hidden;
  background-color: white;
  border-bottom: 1px solid #82d7db;
  position: sticky;
  top: 0;
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  flex-wrap: wrap;
}
#footer {
  margin-top: 20rem;
  padding-top: 1rem;
  overflow: hidden;
  display: flex;
  background-color: #ffffff;
  align-items: flex-start;
  border-top: 1px solid #82d7db;
  bottom: 0;
  width: 100%;
  flex-direction: column;
}
#footer a {
  float: right;
  display: block;
  color: #1d375b;
  font-weight: bold;
  text-align: center;
  padding: 16px 16px;
  text-decoration: none;
}
#userMenu p {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  text-decoration: none;
  margin-left: 16px;
}
#userMenu a {
  float: right;
  display: flex;
  color: #1d375b;
  font-weight: bold;
  text-align: center;
  padding: 16px 16px;
  text-decoration: none;
  position: relative;
  left: -3%;
}
#userMenu a:hover {
  background: #f1f8f8;
  color: black;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup .popup-inner {
  background: transparent;
  padding: 32px;
}
.scBrand_list {
  display: flex;
  flex-direction: row;
}
.scBrand_list_One {
  display: flex;
  flex-direction: row;
}
.frame-35582 {
  /* padding: 0 80px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 90%;
  position: relative;
}
.frame-355821 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  width: 90%;
  position: relative;
}
.navbar-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  gap: 10px;
}
.frame-7 {
  display: flex;
  flex-direction: row;
  gap: 36px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-1 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.image-2 {
  flex-shrink: 0;
  width: 173px;
  height: 40px;
  position: relative;
  object-fit: cover;
}
.frame-6 {
  border-radius: 16px;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.solutions {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  font-weight: 600;
  position: relative;
}
.iconly-sharp-arrow-down-2-instance {
  flex-shrink: 0 !important;
}
.frame-5 {
  border-radius: 16px;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.pricing {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  font-weight: 600;
  position: relative;
}
.frame-4 {
  border-radius: 16px;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.partnership {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  cursor: pointer;
}
.frame-72 {
  border-radius: 16px;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.blogs {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  cursor: pointer;
}
.frame-8 {
  border-radius: 16px;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.tools {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  font-weight: 600;
  position: relative;
}
.tooltip-message {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.frame-9 {
  border-radius: 16px;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.about {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  cursor: pointer;
}
.frame-82 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  position: relative;
}
.logout-button {
  display: flex;
  min-width: 150px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 13px;
  background: #11365a;
  height: auto;
  border: none;
}
.hamburger-menu {
  cursor: pointer;
  margin-top: 10px;
}
.drawer-navigation {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 999;
  width: 30%;
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
}
.drawer-navigation-loggedin {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: white;
  z-index: 999;
  gap: 8px;
  width: 60%;
  /* height: 100%; */
  padding: 20px;
  box-shadow: 4px 5px 15px rgba(0, 0, 0, 0.2);
}
.drawer-item {
  padding: 10px 0;
}
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}
.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  backdrop-filter: blur(10px);
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}
.bg-b-lue-mobile {
  background: rgba(0, 0, 0, 8%);
  backdrop-filter: blur(10px);
  z-index: 999;
  position: fixed;
  top: 70px;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
}
.router-link-no-hover {
  cursor: pointer;
  background-color: transparent !important;
}

.dashboard-navigation-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 18vw;
  height: 100vh;
  /* position: fixed; */
  z-index: 1000;
  flex-wrap: wrap;
  background-color: white;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.18);
  pointer-events: auto;
}
.dasboard-nav-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 5%;
  width: 18vw;
  height: 95%;
  position: relative;
  gap: 14px;
}
.navbar-optins-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.navbar-optins {
  border-radius: 16px;
  padding: 12px 0;
  gap: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}
.navbar-option {
  border-radius: 16px;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  text-decoration: none;
}
.navbar-option:hover {
  background-color: #11365a1f;
  border-radius: 16px;
}
.active-link {
  background: #11365a !important;
  border-radius: 16px;
}
.option-lable {
  /* color: #11365a; */
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 15px;
  line-height: 28px;
  font-weight: 600;
  position: relative;
}
.line-16 {
  border-style: solid;
  border-color: var(--secondary-grey-300, #f4f7fe);
  border-width: 1px 0 0 0;
  align-self: stretch;
  flex-shrink: 0;
  height: 0px;
  position: relative;
}
.profile-button {
  background: #ffffff;
  border-radius: 16px;
  border: 1px solid rgba(17, 54, 90, 0.5);
  padding: 5px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
}
.profile-button:hover {
  background: #11365a1f;
}
.user-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.user-name {
  color: var(--dark-blue, #11365a);
  text-align: left;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  position: relative;
  overflow-wrap: anywhere;
}
.profile-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 15px;
  border: 2px solid rgba(38, 187, 194, 0.4);
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.25);
  width: 25vw;
  background-color: white;
  top: -133px;
  overflow: hidden;
}
.profile-menu-item {
  display: flex;
  padding: 16px;
  align-items: center;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  color: var(--dark-blue, #11365a);
  text-align: left;
  align-self: stretch;
  cursor: pointer;
}
.profile-menu-item:hover {
  background-color: #26bac237;
}

.menu-wrapper {
  width: 30px;
  height: 18px;
  cursor: pointer;
  position: relative;
}

.menu-bar {
  position: absolute;
  width: 30px;
  height: 2px;
  background: #000000;
  /* left: 0%; */
}

.one {
  top: 0px;
  animation-delay: 0.1s;
  transition: all 0.3s;
}

.two {
  top: 8px;
  transition: all 0.3s;
}

.three {
  top: 16px;
  transition: all 0.3s;
}
.validity {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 16px; */
  align-self: stretch;
  border-radius: 13px;
  background: var(--Danger-Light-BG, #ffc9c9);
}
.validity-title1 {
  color: var(--Danger-Text, #861f1f);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-align: left;
}
.validity-title2 {
  color: var(--Danger-Text, #861f1f);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
}
@keyframes slideOut {
  0% {
    width: 100%;
    left: 0%;
    right: auto;
  }

  50% {
    width: 0%;
    left: 0%;
    right: auto;
  }
  51% {
    width: 0%;
    right: 0%;
    left: auto;
  }

  100% {
    width: 100%;
    right: 0%;
    left: auto;
  }
}

/**/

.menu-wrapper:hover .menu-bar.active {
  animation: none;
}
.active .one {
  top: 50%;
  left: 0%;
  transform: rotate(45deg);
}
.active .two {
  opacity: 0;
  transition: opacity 0.2s;
}
.active .three {
  top: 50%;
  left: 0%;
  transform: rotate(-45deg);
}
.reward {
  height: 23px;
  width: 23px;
}

@media only screen and (max-width: 767px) {
  #userMenu a {
    padding: 8px 8px;
    width: 100%;
  }
  .image-2 {
    width: 110px;
    height: auto;
  }
  .menu-bar {
    width: 28px;
  }
  .profile-menu {
    width: 89%;
    /* bottom: 97px; */
  }
  .wishlist-title h3 {
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
  .cart-items {
    font-size: 14px;
  }
  .validity-title1,
  .validity-title2 {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  #userMenu a {
    padding: 0;
  }
  .image-2 {
    width: 136.5px;
    height: auto;
  }
  .bg-b-lue-mobile {
    top: 81px;
  }
  .drawer-navigation-loggedin {
    width: 30%;
  }
  #userMenu a {
    padding: 8px 8px;
    width: 100%;
  }
  .profile-menu {
    width: 89%;
    /* bottom: 97px; */
  }
  .validity-title1,
  .validity-title2 {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1023px) and (max-width: 1220px) {
  .solutions,
  .pricing,
  .partnership,
  .blogs,
  .tools,
  .about {
    font-size: 16px;
  }
  .frame-82 {
    /* margin-left: 2%; */
    gap: 20px;
    justify-content: space-between;
    /* width: 30%; */
  }
  .router-link-no-hover {
    padding: 0 !important;
  }
  /* .frame-7 {
    gap: 0;
    justify-content: space-between;
    width: 70%;
  } */
}

@media only screen and (min-width: 1024px) {
}
</style>
