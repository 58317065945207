<template>
  <div :class="'tab-section-property-1-default ' + 'property-1-' + property1">
    <div class="frame-35637">
      <div class="frame-16">
        <div v-if="show" class="frame-35575">
          <img class="rectangle-3" v-lazy="require('@/assets/GlobalCoverage.webp')" alt="Global Coverage with focus on Asia - 230+ Countries"/>
          <div class="frame-35576">
            <div class="global-coverage-with-focus-on-asia-230-countries">
              <span>
                <span
                  class="global-coverage-with-focus-on-asia-230-countries-span"
                >
                  Global Coverage with focus on Asia
                  <br />
                </span>
                <span
                  class="global-coverage-with-focus-on-asia-230-countries-span2"
                >
                  230+ Countries
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="frame-14">
          <div class="intelligence-solutions-overview">
            Intelligence Solutions Overview
          </div>
          <div
            class="our-intelligence-solutions-provide-real-time-credible-business-information-to-banks-corporates-and-sm-es-with-access-to-over-300-million-counterparts-and-1-billion-shipment-records-we-help-our-customers-make-informed-decisions-for-counterparty-risk-assessment-buyer-and-supplier-onboarding-competitor-intelligence-and-more"
          >
            Our intelligence solutions provide real-time, credible business
            information to banks, corporates, and SMEs. With access to over 300
            million counterparts and 1 billion shipment records, we help our
            customers make informed decisions for counterparty risk assessment,
            buyer and supplier onboarding, competitor intelligence, and more.
          </div>
        </div>
        <div class="frame-14">
          <div class="intelligence-solutions-overview">
            Counterparty Risk Assessment
          </div>
          <div
            class="our-intelligence-solutions-provide-real-time-credible-business-information-to-banks-corporates-and-sm-es-with-access-to-over-300-million-counterparts-and-1-billion-shipment-records-we-help-our-customers-make-informed-decisions-for-counterparty-risk-assessment-buyer-and-supplier-onboarding-competitor-intelligence-and-more"
          >
            We offer comprehensive counterparty risk assessment services,
            leveraging our extensive database of over 400 data points. Our
            real-time data and advanced analysis techniques ensure accurate risk
            evaluation for financial institutions, corporates, and SMEs.
          </div>
        </div>
        <div class="frame-14">
          <div class="intelligence-solutions-overview">
            Buyer and Supplier Onboarding
          </div>
          <div
            class="our-intelligence-solutions-provide-real-time-credible-business-information-to-banks-corporates-and-sm-es-with-access-to-over-300-million-counterparts-and-1-billion-shipment-records-we-help-our-customers-make-informed-decisions-for-counterparty-risk-assessment-buyer-and-supplier-onboarding-competitor-intelligence-and-more"
          >
            Streamline your buyer and supplier onboarding process with our
            intelligence solutions. We provide up-to-date information on
            potential counterparts, enabling you to make informed decisions and
            mitigate risks associated with new business relationships.
          </div>
        </div>
      </div>
      <div v-if="!show" class="frame-35575">
        <img class="rectangle-3" v-lazy="require('@/assets/GlobalCoverage.webp')" alt="Global Coverage with focus on Asia - 230+ Countries"/>
        <div class="frame-35576">
          <div class="global-coverage-with-focus-on-asia-230-countries">
            <span>
              <span
                class="global-coverage-with-focus-on-asia-230-countries-span"
              >
                Global Coverage with focus on Asia
                <br />
              </span>
              <span
                class="global-coverage-with-focus-on-asia-230-countries-span2"
              >
                230+ Countries
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TabSectionProperty1Default",
  components: {},
  props: {
    property1: {
      type: String,
      default: "default",
    },
  },
  data() {
    return {
      show: window.innerWidth < 1023,
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.show = window.innerWidth < 767;
    },
  },
};
</script>
<style scoped>
.tab-section-property-1-default,
.tab-section-property-1-default * {
  box-sizing: border-box;
}
.tab-section-property-1-default {
  background: #ffffff;
  flex-shrink: 0;
  position: relative;
}
.frame-35637 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10%;
  width: 85%;
}
.frame-16 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 50%;
  position: relative;
}
.frame-14 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.intelligence-solutions-overview {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 24px;
  font-weight: 700;
  position: relative;
  align-self: stretch;
}
.our-intelligence-solutions-provide-real-time-credible-business-information-to-banks-corporates-and-sm-es-with-access-to-over-300-million-counterparts-and-1-billion-shipment-records-we-help-our-customers-make-informed-decisions-for-counterparty-risk-assessment-buyer-and-supplier-onboarding-competitor-intelligence-and-more {
  color: #3e4855;
  text-align: justify;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
  align-self: stretch;
  width: 85%;
}
.frame-35575 {
  border-radius: 24px;
  border: 2px solid rgba(20, 45, 77, 0.13);
  border-image-slice: 1;
  flex-shrink: 0;
  width: 50%;
  /* height: 550px; */
  position: relative;
  box-shadow: 0px 16px 24px 0px rgba(20, 45, 77, 0.1);
  overflow: hidden;
}
.rectangle-3 {
  width: 100%;
  height: 100%;
  position: relative;
  object-fit: cover;
}
.frame-35576 {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 51.99999809265137%
  );
  border-radius: 20px;
  padding: 30px 50px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 50%;
  position: absolute;
  left: 0px;
  bottom: 0px;
}
.global-coverage-with-focus-on-asia-230-countries {
  text-align: center;
  position: relative;
}
.global-coverage-with-focus-on-asia-230-countries-span {
  color: #11365a;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 24px;
  font-weight: 600;
}
.global-coverage-with-focus-on-asia-230-countries-span2 {
  color: #e4004c;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 32px;
  font-weight: 700;
}

/* Media query */
@media only screen and (max-width: 767px) {
  .frame-35637 {
    margin-top: 1rem;
    justify-content: center;
  }
  .frame-16 {
    width: 90%;
    align-items: center;
  }
  .frame-35575 {
    border-radius: 18px;
    width: 93%;
    height: auto;
  }
  .frame-35576 {
    padding: 0 30px 10px 30px;
  }
  .rectangle-3 {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .frame-14 {
    padding: 20px;
  }
  .intelligence-solutions-overview {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
  .our-intelligence-solutions-provide-real-time-credible-business-information-to-banks-corporates-and-sm-es-with-access-to-over-300-million-counterparts-and-1-billion-shipment-records-we-help-our-customers-make-informed-decisions-for-counterparty-risk-assessment-buyer-and-supplier-onboarding-competitor-intelligence-and-more {
    font-size: 12px;
    text-align: center;
    width: 100%;
  }
  .global-coverage-with-focus-on-asia-230-countries-span {
    font-size: 14px;
    font-weight: 600;
  }
  .global-coverage-with-focus-on-asia-230-countries-span2 {
    font-size: 18px;
    font-weight: 600;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .frame-35637 {
    margin-top: 3rem;
    justify-content: center;
  }
  .frame-16 {
    width: 50%;
    align-items: center;
  }
  .frame-35575 {
    border-radius: 18px;
    width: 50%;
    height: auto;
  }
  .frame-35576 {
    padding: 0 30px 10px 30px;
  }
  .rectangle-3 {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .frame-14 {
    padding: 20px 0;
    margin: 3% 0;
  }
  .intelligence-solutions-overview {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
  }
  .our-intelligence-solutions-provide-real-time-credible-business-information-to-banks-corporates-and-sm-es-with-access-to-over-300-million-counterparts-and-1-billion-shipment-records-we-help-our-customers-make-informed-decisions-for-counterparty-risk-assessment-buyer-and-supplier-onboarding-competitor-intelligence-and-more {
    font-size: 16px;
    width: 100%;
    text-align: center;
  }
  .global-coverage-with-focus-on-asia-230-countries span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: rem;
    margin-bottom: 1rem;
  }
  .global-coverage-with-focus-on-asia-230-countries-span {
    font-size: 22px;
    font-weight: 600;
  }
  .global-coverage-with-focus-on-asia-230-countries-span2 {
    font-size: 30px;
    font-weight: 600;
    margin-top: 3%;
  }
}

@media only screen and (min-width: 1024px) {
}
</style>
