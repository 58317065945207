<template>
  <div id="dashboardSvgMap"></div>
</template>

<script>
import svgMap from "svgmap";
import "svgmap/dist/svgMap.min.css";
// import { onMounted, ref } from "vue";
import { GetDashboardData } from "@/api/APIs";

export default {
  name: "DashboardMap",
  components: {},
  props: {},
  data() {
    return {
      dashboardData: [],
      dashboardMap: {
        targetElementID: "dashboardSvgMap",
        mouseWheelZoomEnabled: false,
        hideFlag: false,
        colorMax: "#11365A",
        colorMin: "#d5e4f3",
        colorNoData: "white",
        data: {
          data: {
            orderCount: {
              name: "Total Orders -",
              format: "",
            },
          },
          applyData: "orderCount",
          values: {},
        },
      },
    };
  },
  mounted() {
    this.DashboardData();
  },
  methods: {
    async DashboardData() {
      try {
        const response = await GetDashboardData();
        if (response) {
          this.dashboardData = response;
          this.renderMap();
        } else {
          return this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    renderMap() {
      this.generateValues();
      new svgMap(this.dashboardMap);

      this.$nextTick(() => {
        setTimeout(this.selectDefaultCountry, 100);
      });
    },
    generateValues() {
      const values = {};
      this.dashboardData?.order_countries.forEach((data) => {
        values[data?.code] = {
          orderCount: data?.order_count,
          //   link: `search/${country.name.replace(/\s+/g, "-")}`,
        };
      });
      this.dashboardMap.data.values = values;
    },
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

:deep(.svgMap-map-wrapper .svgMap-map-image) {
  background-color: white;
}

:deep(.svgMap-map-wrapper .svgMap-country) {
  stroke: #11365a !important;
  stroke-width: 0.5 !important;
  transition: fill 250ms, stroke 100ms !important;
}
:deep(.svgMap-tooltip) {
  background: white !important;
  color: #11365a !important;
  border-radius: 10px !important;
}
:deep(.svgMap-tooltip-pointer::after) {
  background-color: white !important;
}
:deep(.svgMap-tooltip .svgMap-tooltip-content),
:deep(.svgMap-tooltip .svgMap-tooltip-content table tbody tr td span) {
  color: #11365a !important;
}
:deep(.svgMap-tooltip-no-data) {
  color: rgb(156, 178, 200) !important;
}
</style>
