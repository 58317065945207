<template>
  <div class="loader-wrapper">
    <LoaderAnimation />
  </div>
  <Toastify ref="toastContainer" />
</template>

<script>
import { TeamInviteValidation, AcceptTeamInvite } from "@/api/APIs";
import Toastify from "@/components/Toastify.vue";
import VueCookies from "vue-cookies";
import LoaderAnimation from "@/assets/Animation/LoaderAnimation.vue";

export default {
  name: "TeamInvite",
  components: {
    Toastify,
    LoaderAnimation,
  },
  data() {
    return {
      email: "",
      invitationToken: "",
    };
  },
  created() {
    this.invitationToken = this.$route.query.tok;
    this.email = this.$route.query.email;
  },
  mounted() {
    this.InvitationValidation();
  },
  computed: {},
  methods: {
    async InvitationValidation() {
      let payload = {
        email: this.email,
        token: this.invitationToken,
      };
      try {
        const response = await TeamInviteValidation(payload);
        if (response?.message == "USER_EXISTS") {
          if (VueCookies.get("token")) {
            this.AcceptInvitation();
          } else {
            this.$router.push({
              path: "/login",
              query: {
                type: "Login",
                email: this.email,
                tok: this.invitationToken,
              },
            });
          }
        } else if (response.message == "VALID") {
          this.$router.push({
            path: "/login",
            query: {
              type: "SignUp",
              email: this.email,
              tok: this.invitationToken,
            },
          });
        } else if (response.message == "INVALID") {
          return this.$refs.toastContainer.addToast(
            "This invitation have expired or not valid",
            {
              type: "error",
            }
          );
        } else {
          return this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Oops! Something went wrong, Please try again later",
            {
              type: "error",
            }
          );
        }
      }
    },
    async AcceptInvitation() {
      let payload = {
        token: this.invitationToken,
      };
      try {
        const response = await AcceptTeamInvite(payload);
        if (response?.message == "SUCCESS") {
          this.$refs.toastContainer.addToast(
            "Invitation to join the team accepted successfully",
            {
              type: "success",
            }
          );
          this.$router.push({ name: "SearchForCompanies" });
        } else {
          return this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Oops! Something went wrong, Please try again later",
            {
              type: "error",
            }
          );
        }
      }
    },
  },
};
</script>

<style scoped>
.loader-wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 8%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  right: 0;
  z-index: 1000;
}
</style>
