<template>
  <div id="svgMap"></div>
</template>

<script>
import svgMap from "svgmap";
// import { onMounted, ref } from "vue";
import { AllCountries } from "../../../api/APIs";

export default {
  name: "App",
  components: {},
  data() {
    return {
      countries: [],
      map: {
        targetElementID: "svgMap",
        mouseWheelZoomEnabled: false,
        hideFlag: false,
        mapBackground: "#fff",
        data: {
          data: {
            time: {
              name: "Time -",
              format: "",
            },
          },
          applyData: "time",
          values: {},
        },
      },
    };
  },
  mounted() {
    this.fetchCountryList();
    // this.setIndiaHovered();
  },
  methods: {
    // setIndiaHovered() {
    //   const indiaPath = document.querySelector('path[data-id="IN"]');
    //   if (indiaPath) {
    //     indiaPath.dispatchEvent(new Event("mouseover"));
    //   }
    // },
    async fetchCountryList() {
      try {
        const response = await AllCountries(false);
        this.countries = await response.allCountries;
        this.renderMap();
      } catch (error) {
        this.loginError = "Error occurred during login.";
        console.error(error);
      }
    },
    renderMap() {
      this.generateValues();
      new svgMap(this.map);

      this.$nextTick(() => {
        setTimeout(this.selectDefaultCountry, 100);
      });
    },
    generateValues() {
      const values = {};
      this.countries?.forEach((country) => {
        values[country.code] = {
          time: `${country.productOptions.REPORT_COMPREHENSIVE.FRESH.eta_hours} hours`,
          link: `search/${country.name.replace(/\s+/g, "-")}`
        };
      });
      this.map.data.values = values;
    },
  },
  selectDefaultCountry() {
    const countryId = "IN";
    const countryElement = document.querySelector(
      `#svgMap svg [data-id="${countryId}"]`
    );

    if (countryElement) {
      countryElement.dispatchEvent(new Event("mouseover"));
    }
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

:deep(.svgMap-map-wrapper .svgMap-map-image) {
  background-color: white;
}

:deep(.svgMap-map-wrapper .svgMap-country) {
  stroke: black !important;
  fill: white;
  stroke-width: 0.5 !important;
  transition: fill 250ms, stroke 100ms !important;
}
:deep(.svgMap-map-wrapper .svgMap-country:hover) {
  fill: #26bbc2;
}
:deep(.svgMap-tooltip) {
  background: #142d4d !important;
  color: white !important;
  border-radius: 10px !important;
}
:deep(.svgMap-tooltip-pointer::after) {
  background-color: #142d4d !important;
}
:deep(.svgMap-tooltip .svgMap-tooltip-content),
:deep(.svgMap-tooltip .svgMap-tooltip-content table tbody tr td span) {
  color: white !important;
}
:deep(.svgMap-tooltip-no-data) {
  color: white !important;
}
</style>
