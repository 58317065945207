<template>
  <vue-toastify :toasts="toasts" />
</template>

<script>
import { ref } from 'vue';
import { toast } from 'vue3-toastify';

export default {
  name: 'ToastifyMessage',
  setup() {
    const toasts = ref([]);

    const addToast = (message, options) => {
      const defaultOptions = {
        theme: 'light',
        position: 'bottom-left',
        transition: 'slide',
        dangerouslyHTMLString: false,
        closeOnClick: true,
        toastStyle: {
          // backgroundColor: 'rgba(17, 54, 90)',
          borderRadius: '10px'
        },
        ...options
      };
      toast(message, defaultOptions);
      toasts.value.unshift({ message, options });
    };

    return {
      addToast,
      toasts
    };
  }
};
</script>
