<template>
    <div class="cart-animation-wrapper">
    <div class="cart-animation-container"></div>
</div>
  </template>
  
  <script>
  import lottie from 'lottie-web';
  import CartCheckout from './CartCheckout.json';

  
  export default {
    mounted() {
      this.loadAnimation();
    },
    methods: {
      loadAnimation() {
        lottie.loadAnimation({
          container: document.querySelector('.cart-animation-container'),
          animationData: CartCheckout,
          renderer: 'svg',
          loop: true,
          autoplay: true,
        });
      },
    }
  }
  </script>
  
  <style scoped>
  .cart-animation-wrapper {
    display: flex;
    /* border-radius: 50%; */
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: transparent;

  }
  .cart-animation-container {
    height: 50px;
    display: flex;
    align-items: center;
    position: relative;
  }
  </style>
  