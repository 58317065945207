<template>
  <div class="vuesax-broken-arrow-up">
    <svg
      class="vuesax-broken-arrow-up2"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.69 12.78C19.35 15.65 17.99 18 14.68 18H12H9.33C6.02 18 4.66 15.65 6.32 12.78L7.66 10.47L9 8.16C10.66 5.29 13.37 5.29 15.03 8.16"
        stroke="black"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <g opacity="0"></g>
    </svg>
  </div>
</template>
<script>
export default {
  name: "VuesaxBrokenArrowUp",
  components: {},
  props: {},
  data() {},
};
</script>
<style scoped>
.vuesax-broken-arrow-up,
.vuesax-broken-arrow-up * {
  box-sizing: border-box;
}
.vuesax-broken-arrow-up {
  width: 24px;
  height: 24px;
  position: relative;
}
.vuesax-broken-arrow-up2 {
  height: auto;
  position: absolute;
  left: 0px;
  top: -10px;
  overflow: visible;
}
</style>
