<template>
  <div class="bg-b-lue" v-on:click.self="redirectToHomePage()">
    <div class="frame-35628" v-if="companyName !== ''">
      <div class="close-icon-wrapper">
        <i class="fa-solid fa-xmark fa-xl" @click="redirectToHomePage()"></i>
      </div>
      <PaymentFailure id="completed-animation" />
      <div class="company-name-wrapper" style="margin-top: 10px">
        Payment is incomplete!
      </div>
      <div class="company-name" v-if="!isBulkOrder">
        Your order for {{ companyName }} is pending.
      </div>
      <div class="company-name" v-else>Your orders are pending.</div>
      <div class="report-delivery-time">
        Please retry the payment or if you need assistance, reach out to our
        team.
      </div>
      <div
        class="button"
        style="
          background-color: white;
          border: 2px solid #11365a;
          padding: 14px 0px;
        "
        v-on:click.prevent="redirectToRepaymentLink()"
      >
        <div class="button-text" style="color: #11365a">Retry Payment</div>
      </div>
    </div>
    <div v-else><LoaderAnimation /></div>
  </div>
</template>
<script>
import PaymentFailure from "@/assets/Animation/PaymentFailure/PaymentFailure.vue";
import { PaymentRetry, PaymentNoSuccess } from "@/api/APIs";
import LoaderAnimation from "@/assets/Animation/LoaderAnimation.vue";

export default {
  name: "PaymentRetryModal",
  components: {
    PaymentFailure,
    LoaderAnimation,
  },
  data() {
    return {
      paymentLink: "",
      companyName: "",
      isLoading: true,
      isBulkOrder: false,
    };
  },
  async mounted() {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const orderEmail = urlParams.get("orderEmail");
      const paymentSecret = urlParams.get("paymentSecret");
      const doNewPayment = urlParams.get("doNewPayment") ? true : false;
      this.isBulkOrder = urlParams.get("doNewPayment") ? true : false;
      if (orderEmail && paymentSecret) {
        const response = await PaymentNoSuccess(
          orderEmail,
          paymentSecret,
          doNewPayment
        );
        if (response && response?.subjectName) {
          this.companyName = response?.subjectName;
        }
      } else {
        throw new Error("Missing orderEmail or paymentSecret");
      }
    } catch (error) {
      console.error("Error confirming payment:", error);
    } finally {
      this.isLoading = false;
    }
    2;
  },
  methods: {
    async redirectToRepaymentLink() {
      const urlParams = new URLSearchParams(window.location.search);
      const orderEmail = urlParams.get("orderEmail");
      const paymentSecret = urlParams.get("paymentSecret");
      const doNewPayment = urlParams.get("doNewPayment") ? true : false;
      const response = await PaymentRetry(
        orderEmail,
        paymentSecret,
        doNewPayment
      );
      if (response && response?.result?.paymentRequired === true) {
        window.location.href = response?.result?.paymentLink;
      }
    },
    redirectToHomePage() {
      if (this.isLoading) {
        return;
      } else {
        this.$router.push({ name: "new-home" });
      }
    },
  },
};
</script>
<style scoped>
.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  z-index: 9999;
}
.frame-35628 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 420px;
  position: absolute;
  background: #ffffff;
  border-radius: 24px;
  position: relative;
  box-shadow: 0px 24px 24px 0px rgba(17, 54, 90, 0.2);
  padding: 2rem 3rem;
}
.company-name-wrapper {
  color: var(--Dark-Blue, #11365a);
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5px;
}
.company-name {
  color: var(--Dark-Blue, #11365a);
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  margin-top: 5px;
}
.report-delivery-time {
  color: var(--Text-Mid, #3e4855);
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.18px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.button {
  border-radius: 13px;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-width: 150px;
  width: 100%;
  cursor: pointer;
}
.button-text {
  color: #ffffff;
  text-align: center;
  font-family: "NunitoSans-SemiBold", sans-serif;
  font-size: 18px;
  font-weight: 600;
  flex: 1;
}
.close-icon-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  align-self: stretch;
  margin-top: 10px;
}
@media only screen and (max-width: 767px) {
  .frame-35628 {
    width: 70%;
    padding: 1rem;
  }
  #completed-animation {
    width: 75px;
    height: 75px;
  }
  .company-name-wrapper,
  .button-text,
  .company-name {
    font-size: 14px;
  }
  .report-delivery-time {
    font-size: 12px;
  }
  .button {
    padding: 8px 0 !important;
  }
}
</style>
