<template>
  <div
    class="popup bg-b-lue"
    v-on:click.self="invitationStatus ? closeSuccess() : close()"
  >
    <div :class="invitationStatus ? 'success' : 'frame-35628'">
      <div class="title" v-if="!invitationStatus">
        Invite Friends and Earn Rewards!
      </div>
      <div class="add-members-wrapper" v-if="!invitationStatus">
        <input
          type="email"
          placeholder="Invitee's Email"
          class="invitee-email"
          autocomplete="off"
          id="inviteeEmail"
          v-model="inviteeEmail"
          for="inviteeEmail"
        />
        <div class="add-button" @click="addInvitee">
          <span>Add</span>
        </div>
      </div>
      <div class="invitees-wrapper" v-if="!invitationStatus">
        <div class="invitees-title">You are inviting:</div>
        <div class="invitees-list invitees-list-height">
          <div class="table-wrapper" v-if="invitees && invitees?.length !== 0">
            <table
              id="searchResultTable"
              v-if="invitees && invitees.length > 0"
            >
              <tbody>
                <tr
                  v-for="(invitee, index) in invitees"
                  :style="hoveredRow2 === index ? hoverStyle : {}"
                  :key="index"
                >
                  <td>
                    {{ invitee }}
                  </td>
                  <td>
                    <div class="remove-invitee" @click="removeInvitee(index)">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M10.9425 6L9 7.9425L7.0575 6L6 7.0575L7.9425 9L6 10.9425L7.0575 12L9 10.0575L10.9425 12L12 10.9425L10.0575 9L12 7.0575L10.9425 6ZM9 1.5C4.8525 1.5 1.5 4.8525 1.5 9C1.5 13.1475 4.8525 16.5 9 16.5C13.1475 16.5 16.5 13.1475 16.5 9C16.5 4.8525 13.1475 1.5 9 1.5ZM9 15C5.6925 15 3 12.3075 3 9C3 5.6925 5.6925 3 9 3C12.3075 3 15 5.6925 15 9C15 12.3075 12.3075 15 9 15Z"
                          fill="#FC402B"
                        />
                      </svg>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="no-invitees"
            v-if="!invitationSent && invitees?.length === 0"
          >
            <AddTeamMembers />
            Add friends to be invited to the Credence
          </div>
        </div>
        <div
          v-if="!invitationStatus"
          :class="[
            'create-button',
            { disabled: invitees.length === 0 || loading },
          ]"
          @click="invitees.length === 0 || loading ? null : SendInvitation()"
          :style="{ opacity: loading ? 0.5 : 1 }"
        >
          <span>Invite</span>
        </div>
        <div v-if="!invitationStatus" class="cancel-button" @click="close()">
          <span>Cancel</span>
        </div>
      </div>
      <CompletedAnimationJSON v-if="invitationStatus" />
      <div
        class="company-name-wrapper"
        style="margin-top: 10px"
        v-if="invitationStatus"
      >
        <span style="font-weight: 600"
          >Invite sent!</span
        >
        <span>Earn {{ this.$root.referralCredits }} reward for every friend who places an order</span>
        <span
          >For more details see
          <span
            style="
              font-weight: 600;
              text-decoration: underline;
              cursor: pointer;
            "
            @click="
              $router.push('/rewards');
              closeSuccess();
            "
            >Rewards Dashboard</span
          ></span
        >
      </div>
      <div
        class="cancel-button"
        v-if="invitationStatus"
        @click="closeSuccess()"
      >
        <span>Close</span>
      </div>
    </div>
  </div>
  <Toastify ref="toastContainer" />
</template>
<script>
import { ReferUser, FindUser } from "@/api/APIs";
import Toastify from "@/components/Toastify.vue";
import AddTeamMembers from "@/assets/Animation/AddTeamMembers/AddTeamMembers.vue";
import CompletedAnimationJSON from "@/assets/Animation/CompletedAnimation.vue";

export default {
  name: "InviteNewUserModal",
  props: {},
  components: {
    Toastify,
    AddTeamMembers,
    CompletedAnimationJSON,
  },
  data() {
    return {
      inviteeEmail: "",
      invitees: [],
      invitationSent: false,
      loading: false,
    };
  },
  computed: {
    invitationStatus() {
      return this.invitationSent;
    },
  },
  async mounted() {},
  methods: {
    closeSuccess() {
      this.$emit("close");
      this.invitationSent = false;
      if (this.$route.path === "/rewards") {
        this.$root.MyReferrals();
      }
    },
    close() {
      this.$emit("close");
    },
    async addInvitee() {
      this.pendingInvitesList = [];
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.inviteeEmail || !emailPattern.test(this.inviteeEmail)) {
        return this.$refs.toastContainer.addToast(
          "Please enter a valid email",
          { type: "error" }
        );
      } else {
        try {
          let payload = {
            email: this.inviteeEmail,
          };
          const response = await FindUser(payload);
          if (response?.message == "No user found") {
            this.invitees.push(this.inviteeEmail);
            this.inviteeEmail = "";
          } else {
            this.$refs.toastContainer.addToast(`User already exist`, {
              type: "error",
            });
          }
        } catch (error) {
          if (error.response && error.response.status !== 403) {
            return this.$refs.toastContainer.addToast(
              "Something went wrong. Please try again later.",
              { type: "error" }
            );
          }
        }
      }
    },
    removeInvitee(index) {
      this.invitees.splice(index, 1);
    },
    async SendInvitation() {
      this.loading = true;
      try {
        let payload = {
          emails: this.invitees,
        };
        const response = await ReferUser(payload);
        if (response.message === "SUCCESS") {
          this.invitationSent = true;
        } else {
          return this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  z-index: 9999;
}
.frame-35628 {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  justify-content: flex-start;
  width: 60vw;
  position: relative;
  background: #ffffff;
  border-radius: 24px;
  min-height: 384px;
  box-shadow: 0px 24px 24px 0px rgba(17, 54, 90, 0.2);
  padding: 2rem 3rem;
}
.success {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  position: relative;
  background: #ffffff;
  border-radius: 24px;
  min-height: auto;
  box-shadow: 0px 24px 24px 0px rgba(17, 54, 90, 0.2);
  padding: 2rem 3rem;
}
.company-name-wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  justify-content: center;
}
.title {
  color: var(--Dark-Blue, #11365a);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-self: stretch;
  text-align: left;
}
.add-members-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  position: relative;
  gap: 8px;
}
.invitee-email {
  padding: 12px 24px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #11365a;
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.16px;
  outline: none;
  /* width: 40%; */
}
.invitees-wrapper,
.invitees-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  gap: 18px;
}
.invitees-list-height {
  height: 45vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: #f2f6fd;
  padding: 10px;
  border-radius: 16px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.invitees-title {
  color: var(--Text-Mid, #3e4855);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.18px;
}
.remove-invitee {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  cursor: pointer;
}
.create-button {
  display: flex;
  color: white;
  font-family: "Montserrat-Regular", sans-serif;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  border-radius: 16px;
  padding: 12px 26px;
  justify-content: center;
  align-items: center;
  background: #11365a;
  cursor: pointer;
  align-self: stretch;
}
.create-button.disabled {
  cursor: not-allowed;
  /* background: var(--Dark-Blue, #11365a); */
}
.cancel-button,
.add-button {
  color: #11365a;
  border: 2px solid #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  border-radius: 16px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-self: stretch;
}
.table-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* margin: 20px 0; */
}
#searchResultTable {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  font-family: "Montserrat-Regular", sans-serif;
}
#searchResultTable th {
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  color: #11365a;
  padding: 10px 16px;
  min-width: 30px;
}
#searchResultTable td {
  text-align: left;
  padding: 10px 16px;
  color: #11365a;
  font-size: 16px;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: -0.16px;
}
.no-invitees {
  align-self: stretch;
  color: #11365a5d;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .frame-35628 {
    width: 80vw;
    padding: 1.5rem 1.5rem;
    gap: 20px;
  }
  .title {
    font-size: 18px;
  }
  .add-members-wrapper {
    flex-direction: column;
    gap: 10px;
  }
  .invitee-email {
    padding: 10px 20px;
  }
  .invitee-email:first-of-type {
    line-height: 20.5px;
  }
  .invitees-list-height {
    height: 30vh;
  }
  .add-button {
    padding: 6px 24px;
  }
  .create-button {
    padding: 8px 24px;
  }
  .cancel-button {
    padding: 6px 24px;
  }
  .invitees-title {
    font-size: 16px;
  }
  .success {
    width: 80vw;
    gap: 15px;
    padding: 2rem 2rem;
  }
  .company-name-wrapper {
    margin-top: 0 !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
  .invitee-email {
    width: 40%;
  }
  .add-button {
    width: 18%;
  }
}
@media only screen and (min-width: 1156px) {
  .invitee-email {
    width: 40%;
  }
  .add-button {
    width: 18%;
  }
}
</style>
