<template>
    <div class="animation-wrapper">
    <div class="animation-container"></div>
</div>
  </template>
  
  <script>
  import lottie from 'lottie-web';
  import Failure from './Failure.json';
  
  export default {
    mounted() {
      this.loadAnimation();
    },
    methods: {
      loadAnimation() {
        lottie.loadAnimation({
          container: document.querySelector('.animation-container'),
          animationData: Failure,
          renderer: 'svg',
          loop: true,
          autoplay: true,
        });
      },
    }
  }
  </script>
  
  <style scoped>
  .animation-wrapper {
    display: flex;
    border-radius: 50%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: transparent;

  }
  .animation-container {
    height: 100px;
    display: flex;
    align-items: center;
    position: relative;
  }
  </style>
  